import { EmailTemplateType } from 'types/serverTypes/emailTypes';
import { ExpertProfileType } from 'types/serverTypes/qnaTypes';
import axios from '../redux/api';
import { getStudyId } from './studyService';

export const getEmailTemplates = async () => {
  const studyId = getStudyId();
  const response = await axios.get(
    `/a/configuration/emailTemplates?studyId=${studyId}`
  );
  return response.data;
};

export const updateEmailTemplate = async (emailTemplate: EmailTemplateType) => {
  await axios.put(`/a/configuration/emailTemplate`, emailTemplate);
};

export const getExpertProfiles = async () => {
  const studyId = getStudyId();
  const response = await axios.get(
    `/a/configuration/expertProfiles?studyId=${studyId}`
  );
  return response.data;
};

export const updateExpertProfile = async (expertProfile: ExpertProfileType) => {
  await axios.put(`/a/configuration/expertProfile`, expertProfile);
};

export const deleteExpertProfile = async (profileId: number) => {
  await axios.delete(`/a/configuration/expertProfile/${profileId}`);
};
