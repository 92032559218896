import React, { useState } from 'react';
import { Steps, Button, message, Form } from 'antd';
import './planNew.scss';
import NewPlanEntry from '../../../components/plan/NewPlanEntry';
import {
  NewPlanStepType,
  NewPlanType,
} from '../../../types/serverTypes/planTypes';
import NewPlanStepEntry from '../../../components/plan/NewPlanStepEntry';
import { useMutation, useQueryClient } from 'react-query';
import { addPlan } from '../../../service/planService';

const { Step } = Steps;
interface PlanNewProp {
  onDone: () => void;
}
const PlanNew = (props: PlanNewProp) => {
  const queryClient = useQueryClient();
  const [current, setCurrent] = useState(0);
  const [newPlan, setNewPlan] = useState<NewPlanType>({
    steps: [],
    title: '',
  });
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const newPlanMutation = useMutation(addPlan, {
    onError: (error, variables, context) => {
      message.error('Error in creating a plan.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('plans');
      message.success('Plan successfully created.');
      props.onDone();
    },
  });

  const onFormFinish = (name, { values }) => {
    if (name === 'newPlanEntryForm') {
      setNewPlan({
        ...newPlan,
        title: values.title,
        numberOfSteps: values.numberOfSteps,
        steps: [],
      });
    } else if (name === 'newPlanStepEntryForm') {
      let stepsData: NewPlanStepType[] = [];
      for (let index = 0; index < (newPlan.numberOfSteps || 0); index++) {
        let stepData: NewPlanStepType = {
          sequence: index + 1,
          description: values[`step_${index + 1}_description`],
          title: values[`step_${index + 1}_title`],
          dataType: values[`step_${index + 1}_dataType`],
          maxItemCount: values[`step_${index + 1}_maxItemCount`],
        };
        stepsData.push(stepData);
      }
      const updatedPlan = {
        ...newPlan,
        steps: stepsData,
      };
      setNewPlan(updatedPlan);

      // submit to api
      newPlanMutation.mutate(updatedPlan);
    }
  };

  const steps = [
    {
      title: 'Plan',
      content: (
        <NewPlanEntry
          current={current}
          onNext={next}
          onPrev={prev}
          onCancel={props.onDone}
          newPlan={newPlan}
        />
      ),
    },
    {
      title: 'Steps',
      content: (
        <NewPlanStepEntry
          current={current}
          onNext={next}
          onPrev={prev}
          onCancel={props.onDone}
          newPlan={newPlan}
        />
      ),
    },
  ];

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </Form.Provider>
  );
};

export default PlanNew;
