import {
  faBookMedical,
  faBooks,
  faBullseyeArrow,
  faCertificate,
  faCogs,
  faCommentsAlt,
  faDatabase,
  faDollarSign,
  faFileChartLine,
  faFileSignature,
  faGamepadAlt,
  faInbox,
  faMegaphone,
  faMicroscope,
  faMoneyBillAlt,
  faTachometerAltFastest,
  faTasks,
  faUser,
  faUserPlus,
  faUsers,
  faVial,
  faUsersCog,
  faBook,
  faFilter,
  faEnvelope,
  faUserMd,
} from '@fortawesome/pro-solid-svg-icons';
import Menu from 'antd/lib/menu';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { loadDashboardDataAsync } from '../../redux/dashboard/dashboard.types';
import * as selectors from '../../redux/selectors';
import IApplicationState from '../../types/state.types';
import AntdIcon from '../antdIcon/AntdIcon';
import SideBar from './SideBar';
import './sidebar.scss';
import { faCalendarAlt, faFileAlt } from '@fortawesome/pro-light-svg-icons';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { useQuery } from 'react-query';
import { StudyFeatureType } from '../../../../server/src/types/studyTypes';
import {
  getDashboardData,
  getStudyFeatures,
  getStudyId,
} from '../../service/studyService';
import { hasAccess } from '../../service/auth/authService';
import {
  ROLE_CARE_NAVIGATOR,
  ROLE_COUNSELOR,
  ROLE_DATA_ANALYST,
  ROLE_EXPERT,
  ROLE_STUDY_MANAGER,
} from '../../constant/RoleConstants';
import FeatureNames from '../util/FeatureNames';
import { DashboardType } from '../../types/serverTypes/dashboardTypes';
import { Spin } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;

interface DispatchProps {
  loadDashboardData: typeof loadDashboardDataAsync.request;
}

interface ComponentProps extends DispatchProps {
  collapsed: boolean;
  currentPath: string;
  studyId: number;
}

const StudySideBar = (props: ComponentProps) => {
  const features = useQuery<StudyFeatureType[]>('studyFeatures', () =>
    getStudyFeatures()
  );
  const dashboard = useQuery<DashboardType>(['dashboard', props.studyId], () =>
    getDashboardData(props.studyId)
  );

  const { collapsed, currentPath } = props;
  const selectedKey = currentPath.replace(/^\/study\/\d+\/?(.*)$/, '/study/$1');

  const menuItems: JSX.Element[] = [];
  const studyId = getStudyId();

  const hasFeature = (featureName) => {
    const filteredFeature = features.data?.filter(
      (feature) => feature.feature === featureName && feature.enabled
    );
    return filteredFeature && filteredFeature?.length > 0;
  };
  if (features.isLoading || dashboard.isLoading) {
    return (
      <SideBar collapsed={collapsed}>
        <Spin />
      </SideBar>
    );
  }
  if (
    hasAccess([
      ROLE_CARE_NAVIGATOR,
      ROLE_COUNSELOR,
      ROLE_DATA_ANALYST,
      ROLE_EXPERT,
      ROLE_STUDY_MANAGER,
    ])
  ) {
    menuItems.push(
      <Menu.Item key="/study/" className="sidebar-menu-item">
        <Link to={`/study/${studyId}`}>
          <AntdIcon fontAwesomeIcon={faTachometerAltFastest} />
          <span>Dashboard</span>
        </Link>
      </Menu.Item>
    );
    menuItems.push(
      <Menu.Item key="/study/study" className="sidebar-menu-item">
        <Link to={`/study/${studyId}/study`}>
          <AntdIcon fontAwesomeIcon={faMicroscope} />
          <span>Study</span>
        </Link>
      </Menu.Item>
    );
    menuItems.push(
      <Menu.Item key="/study/inbox" className="sidebar-menu-item">
        <Link to={`/study/${studyId}/inbox`}>
          <AntdIcon fontAwesomeIcon={faInbox} />
          <span>Inbox ({dashboard.data?.inbox.unreadThreads})</span>
        </Link>
      </Menu.Item>
    );
    if (hasFeature(FeatureNames.APPOINTMENTS)) {
      menuItems.push(
        <Menu.Item key="/study/appointments" className="sidebar-menu-item">
          <Link to={`/study/${studyId}/appointments`}>
            <AntdIcon fontAwesomeIcon={faCalendarAlt} />
            <span>
              Appointments ({dashboard.data?.appointment.unconfirmedCount})
            </span>
          </Link>
        </Menu.Item>
      );
    }
    if (hasFeature(FeatureNames.PLAN)) {
      menuItems.push(
        <Menu.Item key="/study/plan" className="sidebar-menu-item">
          <Link to={`/study/${studyId}/plan`}>
            <ProfileOutlined />
            <span>Plan</span>
          </Link>
        </Menu.Item>
      );
    }
    // Content SubMenu
    menuItems.push(
      <SubMenu
        key="/study/content"
        className="sidebar-menu-item"
        title={
          <span>
            <AntdIcon fontAwesomeIcon={faBook} />
            <span>Content</span>
          </span>
        }
      >
        {hasFeature(FeatureNames.FORUM) && (
          <Menu.Item key="/study/forums" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/forums`}>
              <AntdIcon fontAwesomeIcon={faCommentsAlt} />
              <span>Forums</span>
            </Link>
          </Menu.Item>
        )}
        {hasFeature(FeatureNames.ACTIVITIES) && (
          <Menu.Item key="/study/activities" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/activities`}>
              <AntdIcon fontAwesomeIcon={faGamepadAlt} />
              <span>Activities</span>
            </Link>
          </Menu.Item>
        )}
        {hasFeature(FeatureNames.RESOURCE) && (
          <Menu.Item key="/study/resources" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/resources`}>
              <AntdIcon fontAwesomeIcon={faBooks} />
              <span>Resources</span>
            </Link>
          </Menu.Item>
        )}
        {hasFeature(FeatureNames.QNA) && (
          <Menu.Item key="/study/qna" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/qna`}>
              <AntdIcon fontAwesomeIcon={faBookMedical} />
              <span>
                Ask an Expert ({dashboard.data?.qnas.unansweredCount})
              </span>
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="/study/announcements" className="sidebar-menu-item">
          <Link to={`/study/${studyId}/announcements`}>
            <AntdIcon fontAwesomeIcon={faMegaphone} />
            <span>Announcements</span>
          </Link>
        </Menu.Item>
        {hasFeature(FeatureNames.GOAL_SETTING) && (
          <Menu.Item key="/study/goals" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/goals`}>
              <AntdIcon fontAwesomeIcon={faBullseyeArrow} />
              <span>Goals</span>
            </Link>
          </Menu.Item>
        )}
      </SubMenu>
    );

    // User Management
    if (hasAccess([ROLE_STUDY_MANAGER])) {
      menuItems.push(
        <SubMenu
          key="/study/userManagement"
          className="sidebar-menu-item"
          title={
            <span>
              <AntdIcon fontAwesomeIcon={faUsersCog} />
              <span>User Management</span>
            </span>
          }
        >
          <Menu.Item key="/study/participants" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/participants`}>
              <AntdIcon fontAwesomeIcon={faUsers} />
              <span>Participants</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/study/1/user" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/user`}>
              <AntdIcon fontAwesomeIcon={faUserPlus} />
              <span>User Creation</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/study/admin" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/admin`}>
              <AntdIcon fontAwesomeIcon={faUsersCog} />
              <span>Admin Management</span>
            </Link>
          </Menu.Item>
          {hasFeature(FeatureNames.INCENTIVES) && (
            <Menu.Item key="/study/payments" className="sidebar-menu-item">
              <Link to={`/study/${studyId}/payments`}>
                <AntdIcon fontAwesomeIcon={faMoneyBillAlt} />
                <span>Incentives</span>
              </Link>
            </Menu.Item>
          )}
          {hasFeature(FeatureNames.TEST_KITS) && (
            <Menu.Item key="/study/testkits" className="sidebar-menu-item">
              <Link to={`/study/${studyId}/testkits`}>
                <AntdIcon fontAwesomeIcon={faVial} />
                <span>Test Kits</span>
              </Link>
            </Menu.Item>
          )}
          {hasFeature(FeatureNames.SURVEYS) && (
            <Menu.Item key="/study/surveys" className="sidebar-menu-item">
              <Link to={`/study/${studyId}/surveys`}>
                <AntdIcon fontAwesomeIcon={faTasks} />
                <span>Surveys</span>
              </Link>
            </Menu.Item>
          )}
        </SubMenu>
      );
    }

    // Data Analytics
    if (hasAccess([ROLE_DATA_ANALYST])) {
      menuItems.push(
        <SubMenu
          key="/study/analytics"
          className="sidebar-menu-item"
          title={
            <span>
              <AntdIcon fontAwesomeIcon={faDatabase} />
              <span>Data Analytics</span>
            </span>
          }
        >
          {hasFeature(FeatureNames.INCENTIVES) && (
            <Menu.Item
              key="/study/reports/incentives"
              className="sidebar-menu-item"
            >
              <Link to={`/study/${studyId}/reports/incentives`}>
                <AntdIcon fontAwesomeIcon={faDollarSign} />
                <span>Incentive Reports</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="/study/adHocQuery" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/adHocQuery`}>
              <AntdIcon fontAwesomeIcon={faFileChartLine} />
              <span>Ad Hoc Query Page</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      );
    }

    // Configuration
    if (hasAccess([ROLE_STUDY_MANAGER])) {
      menuItems.push(
        <SubMenu
          key="/study/configuration"
          className="sidebar-menu-item"
          title={
            <span>
              <AntdIcon fontAwesomeIcon={faCogs} />
              <span>Configuration</span>
            </span>
          }
        >
          <Menu.Item key="/study/avatars" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/avatars`}>
              <AntdIcon fontAwesomeIcon={faUser} />
              <span>Avatars</span>
            </Link>
          </Menu.Item>
          {hasFeature(FeatureNames.BADGES) && (
            <Menu.Item key="/study/badges" className="sidebar-menu-item">
              <Link to={`/study/${studyId}/badges`}>
                <AntdIcon fontAwesomeIcon={faCertificate} />
                <span>Badges</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="/study/features" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/features`}>
              <AntdIcon fontAwesomeIcon={faFilter} />
              <span>Features</span>
            </Link>
          </Menu.Item>
          {hasFeature(FeatureNames.FORMS) && (
            <Menu.Item key="/study/form" className="sidebar-menu-item">
              <Link to={`/study/${studyId}/form`}>
                <AntdIcon fontAwesomeIcon={faFileSignature} />
                <span>Forms</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="/study/notification" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/notification`}>
              <AntdIcon fontAwesomeIcon={faBell} />
              <span>Notifications</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/study/stringLookups" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/stringLookups`}>
              <AntdIcon fontAwesomeIcon={faFileAlt} />
              <span>String Lookups</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/study/emailTemplates" className="sidebar-menu-item">
            <Link to={`/study/${studyId}/emailTemplates`}>
              <AntdIcon fontAwesomeIcon={faEnvelope} />
              <span>Email Templates</span>
            </Link>
          </Menu.Item>
          {hasFeature(FeatureNames.QNA) && (
            <Menu.Item
              key="/study/expertProfiles"
              className="sidebar-menu-item"
            >
              <Link to={`/study/${studyId}/expertProfiles`}>
                <AntdIcon fontAwesomeIcon={faUserMd} />
                <span>Expert Profiles</span>
              </Link>
            </Menu.Item>
          )}
        </SubMenu>
      );
    }
  } else {
    // YAB admin users
    if (hasFeature(FeatureNames.FORUM)) {
      menuItems.push(
        <Menu.Item key="/study/forums" className="sidebar-menu-item">
          <Link to={`/study/${studyId}/forums`}>
            <AntdIcon fontAwesomeIcon={faCommentsAlt} />
            <span>Forums</span>
          </Link>
        </Menu.Item>
      );
    }
  }
  return (
    <SideBar collapsed={collapsed}>
      <Menu
        id="sidebar-menu"
        selectedKeys={[selectedKey]}
        mode="inline"
        defaultSelectedKeys={['/home']}
        defaultOpenKeys={[
          '/study/configuration',
          '/study/analytics',
          '/study/userManagement',
          '/study/content',
        ]}
      >
        {...menuItems}
      </Menu>
    </SideBar>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  const { pathname } = state.router.location;
  const { sidebar } = state.ui;

  return {
    collapsed: sidebar.collapsed,
    currentPath: pathname,
    studyId: selectors.getRequestedStudyId(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadDashboardData: () => dispatch(loadDashboardDataAsync.request()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudySideBar);
