import { Button, Col, message, Modal, Popconfirm, Row, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { deletePlan, getPlanDetail } from '../../service/planService';
import { PlanDetailType } from '../../types/serverTypes/planTypes';

interface PlanDetailProp {
  planId: string;
  onClose: () => void;
}
const PlanDetail = (props: PlanDetailProp) => {
  const queryClient = useQueryClient();

  const planDetail = useQuery<PlanDetailType>(
    `planDetail-${props.planId}`,
    () => getPlanDetail(props.planId)
  );

  const onDelete = async () => {
    try {
      await deletePlan(props.planId);
      queryClient.invalidateQueries('plans');
      props.onClose();
    } catch (err) {
      if ((err as any).response) {
        message.error((err as any).response.data);
      } else if (err instanceof Error) {
        message.error(
          'Error in deleting the plan: ' + (err as Error).toString()
        );
      }
    }
  };

  if (planDetail.isLoading) {
    return <Spin />;
  }
  if (!planDetail.data) {
    return <div>No data found.</div>;
  }

  return (
    <Modal
      title={planDetail.data.title}
      visible={true}
      destroyOnClose={true}
      width={800}
      footer={[
        <Popconfirm
          title="Are you sure to delete this plan?"
          onConfirm={onDelete}
          okText="Yes"
          cancelText="No"
          key="delete"
        >
          <Button danger>Delete</Button>
        </Popconfirm>,
        <Button key="ok" onClick={props.onClose} type="primary">
          OK
        </Button>,
      ]}
    >
      {planDetail.data.steps.map((step, index) => {
        return (
          <div key={step.id} style={{ marginBottom: 20 }}>
            <Title level={5}>Step {index + 1}</Title>
            <Row>
              <Col span={8}>Title</Col>
              <Col span={12}>{step.title}</Col>
            </Row>
            <Row>
              <Col span={8}>Description</Col>
              <Col span={12}>{step.description}</Col>
            </Row>
            <Row>
              <Col span={8}>Max Number of Items</Col>
              <Col span={12}>{step.maxItemCount}</Col>
            </Row>
            <Row>
              <Col span={8}>Data Type</Col>
              <Col span={12}>{step.dataType}</Col>
            </Row>
          </div>
        );
      })}
    </Modal>
  );
};

export default PlanDetail;
