import { _ } from 'lodash';
import { Card, message, Spin, Tabs } from 'antd';
import Steps from 'antd/lib/steps';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import BadgesTable from '../../../../components/badge/BadgesTable';
import CommentsTable from '../../../../components/forum/CommentsTable';
import PostsTable from '../../../../components/forum/PostsTable';
import NotificationLogTable from '../../../../components/notification/NotificationLogTable';
import {
  loadRequestedParticipantAsync,
  saveParticipantAsync,
} from '../../../../redux/participants/participants.types';
import * as selectors from '../../../../redux/selectors';
import IApplicationState from '../../../../types/state.types';
import './participant.scss';
import ParticipantEditCard from './ParticipantEditCard';
import ParticipantViewCard from './ParticipantViewCard';
import ThreadsTable from '../../../../components/forum/ThreadsTable';
import ParticipantDemographicsCard from './ParticipantDemographicsCard';
import ParticipantNoteCard from './ParticipantNoteCard';
import { saveParticipantNoteAsync } from '../../../../redux/notes/notes.types';
import { AvatarType } from '../../../../types/serverTypes/avatarTypes';
import {
  CommentType,
  PostType,
} from '../../../../types/serverTypes/forumTypes';
import {
  ParticipantType,
  StudyArmType,
} from '../../../../types/serverTypes/studyTypes';
import { NoteType } from '../../../../types/serverTypes/noteTypes';
import { MessageThreadType } from '../../../../types/serverTypes/messageTypes';
import { BadgeType } from '../../../../types/serverTypes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getParticipant,
  getParticipantNotes,
  getRequestedParticipantTab,
  saveParticipant,
  saveParticipantNote,
} from '../../../../service/participant/participantService';
import { getStudyArms, hasFeature } from '../../../../service/studyService';
import FeatureNames from '../../../../components/util/FeatureNames';
import ParticipantPlanDetail from '../../../../components/plan/participant/ParticipantPlanDetail';
import { useParams } from 'react-router';
import { UrlParticipantType } from '../../../../types';
import ParticipantAppointments from '../../../../components/plan/participant/ParticipantAppointments';

const Tab = Tabs;

const { Step } = Steps;

interface StateProps {
  avatar: Optional<AvatarType>;
  posts: Optional<PostType[]>;
  comments: Optional<CommentType[]>;
  threads: Optional<MessageThreadType[]>;
  participantBadges: Optional<BadgeType[]>;
}

interface DispatchProps {
  loadParticipant: typeof loadRequestedParticipantAsync.request;
  saveParticipant: typeof saveParticipantAsync.request;
  saveParticipantNote: typeof saveParticipantNoteAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {}

const initialState = {
  inEditMode: false as boolean,
  editedParticipant: {} as ParticipantType,
  selectedKey: 'posts' as string,
  activeForm: '',
};
type ComponentState = Readonly<typeof initialState>;

const Participant = (props: StateProps) => {
  const [state, setState] = useState<ComponentState>(initialState);
  const [hasPlanFeature, setHasPlanFeature] = useState<boolean>();
  const [hasAppointmentFeature, setHasAppointmentFeature] = useState<boolean>();

  // get participant info
  const params = useParams<UrlParticipantType>();
  const requestedParticipantId = +params.participantId;
  const participant = useQuery<ParticipantType>(
    `participant-${requestedParticipantId}`,
    () => getParticipant(requestedParticipantId)
  );
  const studyArms = useQuery<StudyArmType[]>('studyArms', getStudyArms);

  useEffect(() => {
    hasFeature(FeatureNames.PLAN, undefined, participant.data?.studyArmId).then(
      (v) => {
        setHasPlanFeature(v);
      }
    );
    hasFeature(
      FeatureNames.APPOINTMENTS,
      undefined,
      participant.data?.studyArmId
    ).then((v) => {
      setHasAppointmentFeature(v);
    });
  }, [participant.data]);

  const participantNote = useQuery(
    `participant-${requestedParticipantId}-note`,
    () => getParticipantNotes(requestedParticipantId)
  );

  const handleEditClick = () => {
    if (participant.data) {
      setState({
        ...state,
        inEditMode: true,
        editedParticipant: participant.data,
      });
    }
  };

  // update participant
  const queryClient = useQueryClient();
  const participantMutation = useMutation(saveParticipant, {
    onError: (error, variables, context) => {
      message.error('Error in updating participant.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(`participant-${requestedParticipantId}`);
      message.success('Participant successfully updated.');
    },
  });

  const handleSaveClick = (participant: ParticipantType) => {
    participantMutation.mutate(participant);
    setState({ ...state, inEditMode: false });
  };

  // update note
  const participantNoteMutation = useMutation(saveParticipantNote, {
    onError: (error, variables, context) => {
      message.error('Error in updating participant note.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(
        `participant-${requestedParticipantId}-note`
      );
      message.success('Participant note successfully updated.');
    },
  });
  const handleSaveNoteClick = (note: NoteType) => {
    participantNoteMutation.mutate(note);
  };

  const handleCancelClick = () => {
    setState({ ...state, inEditMode: false });
  };

  if (participant.isLoading || studyArms.isLoading) {
    return <Spin />;
  }

  if (participant.isError) {
    return <div>Error loading the requested participant.</div>;
  }
  if (participant.data === undefined) {
    return (
      <div>
        The participant does not exist or you do not have permissions to view
        the participant.
      </div>
    );
  }

  const { avatar, posts, threads, comments, participantBadges } = props;

  const studyArm = studyArms.data?.find(
    (d) => d.id === participant.data.studyArmId
  );
  if (studyArm === undefined) {
    return (
      <div>
        The participant is assigned to an invalid study arm or you do not have
        permissions to view the study.
      </div>
    );
  }

  const participantSubpageRequested = getRequestedParticipantTab();
  const participantSubpage =
    participantSubpageRequested === ''
      ? 'Timeline'
      : participantSubpageRequested;

  const { editedParticipant } = state;

  const avatarBase64 = avatar ? avatar.avatar : undefined;

  return (
    <div id="participant">
      <Steps type="navigation" size="small" current={1}>
        <Step title="Accepted" />
        <Step title={`${_.capitalize(participant.status)}`} />
        <Step title="Finished" />
      </Steps>
      <ParticipantViewCard
        avatarBase64={avatarBase64}
        participant={participant.data}
      />
      <ParticipantNoteCard
        participant={participant.data}
        notes={participantNote.data}
        saveClickHandler={handleSaveNoteClick}
      />
      <div id="tab-bar">
        <Tab defaultActiveKey={participantSubpage}>
          <Tab.TabPane tab="Demographics" key="2">
            <div>
              {state.inEditMode ? (
                <ParticipantEditCard
                  studyArm={studyArm}
                  initialParticipant={participant.data}
                  saveClickHandler={handleSaveClick}
                  cancelClickHandler={handleCancelClick}
                />
              ) : (
                <ParticipantDemographicsCard
                  studyArm={studyArm}
                  participant={participant.data}
                  editClickHandler={handleEditClick}
                />
              )}
            </div>
          </Tab.TabPane>
          {hasPlanFeature && (
            <Tab.TabPane tab="Plan" key="10">
              <div>
                <ParticipantPlanDetail participantId={participant.data.id} />
              </div>
            </Tab.TabPane>
          )}
          {hasAppointmentFeature && (
            <Tab.TabPane tab="Appointment" key="appointmentList">
              <div>
                <ParticipantAppointments participantId={participant.data.id} />
              </div>
            </Tab.TabPane>
          )}
          <Tab.TabPane tab="Posts" key="forum">
            <div>
              <PostsTable posts={posts} />
            </div>
          </Tab.TabPane>
          <Tab.TabPane tab="Comments" key="comments">
            <div>
              <CommentsTable comments={comments} />
            </div>
          </Tab.TabPane>
          <Tab.TabPane tab="Message Threads" key="4">
            <Card>
              <div>
                <ThreadsTable threads={threads} />
              </div>
            </Card>
          </Tab.TabPane>
          <Tab.TabPane tab="Notifications" key="6">
            <div>
              <NotificationLogTable participantId={participant.data.id} />
            </div>
          </Tab.TabPane>
          <Tab.TabPane tab="Badges" key="9">
            <div>
              <BadgesTable participantBadges={participantBadges} />
            </div>
          </Tab.TabPane>
        </Tab>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    avatar: selectors.getRequestedStudyParticipantAvatar,
    posts: selectors.getRequestedStudyParticipantPosts,
    comments: selectors.getRequestedStudyParticipantComments,
    threads: selectors.getRequestedStudyParticipantThreads,
    participantBadges: selectors.getRequestedStudyParticipantBadges,
  }
);

export default connect(mapStateToProps, null)(Participant);
