import React, { useEffect, useRef, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Select, Input, Spin, Cascader } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import * as selectors from '../../../redux/selectors';
import '../activity.scss';
import {
  getActivityCategoriesAsync,
  getActivityTypesAsync,
} from '../../../redux/activities/activities.types';
import IApplicationState from '../../../types/state.types';
import FroalaCustomUpload from '../FroalaCustomUpload';
import ActivityItemLabel from '../ActivityItemLabel';
import { updateCmsEditorBeforeSave } from '../../util/Util';
import { ActivityFormStatus } from '../ActivityFormContainer';
import {
  ActivityType,
  ActivityTypeType,
} from '../../../types/serverTypes/activityTypes';
import { useQuery } from 'react-query';
import { TopicType } from '../../../types/serverTypes/forumTypes';
import { getKnowledgeCenterTopics } from '../../../service/topicService';
import '../../resources/editors/resourceEditor.scss';
import { CascaderOptionType } from 'antd/lib/cascader';

const { Item } = Form;
const { Option } = Select;

interface StateProps {
  types: Optional<ActivityTypeType[]>;
}

interface DispatchProps {
  loadCategories: typeof getActivityCategoriesAsync.request;
  loadTypes: typeof getActivityTypesAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  creative: boolean;
  editable: boolean;
  setStatus: (status: ActivityFormStatus) => void;
  setActivity: (props: any) => void;
  setActiveForm: (form: any) => void;
  activity: ActivityType;
}

interface ComponentState {
  showTopicError: boolean;
  selectedTopicId: number;
  selectedSubTopicId: number;
  isLoaded: boolean;
}

const itemStyle = {
  width: '100%',
  marginBottom: '10px',
  marginTop: '10px',
};

const ActivityDetailForm = (props: ComponentProps) => {
  const [state, setState] = useState<ComponentState>({
    showTopicError: false,
    selectedTopicId: -1,
    selectedSubTopicId: -1,
    isLoaded: false,
  });

  const form = useRef<FormInstance>(null);

  const topicSelected = (data: TopicType[]) => {
    const parentTopic = data.find((topic) =>
      topic.children?.find(
        (topicChild) => topicChild.id === props.activity.topicId
      )
    );

    setState({
      ...state,
      selectedTopicId: parentTopic?.id ?? -1,
      selectedSubTopicId: props.activity.topicId,
      isLoaded: true,
    });
    props.setActiveForm(form.current);
  };

  const topics = useQuery<TopicType[]>(
    'resourceTopics',
    getKnowledgeCenterTopics,
    {
      onSuccess: topicSelected,
    }
  );

  useEffect(() => {
    const { setStatus, loadCategories, loadTypes, setActiveForm } = props;
    setStatus(ActivityFormStatus.PROCESS);
    setActiveForm(form.current);
    loadTypes();
  }, []);

  useEffect(() => {
    const { setStatus, activity } = props;
    const { typeId, title, description, intro } = activity;
    if (
      typeId > -1 &&
      title.length > 0 &&
      description.length > 0 &&
      intro.length > 0
    ) {
      setStatus(ActivityFormStatus.FINISH);
    }
  }, [props.activity]);

  const getResourceTopicsCascader = (topics): CascaderOptionType[] => {
    if (topics) {
      const options: CascaderOptionType[] = topics.map((topic) => {
        return {
          value: `${topic.id}`,
          label: topic.title,
          children: !topic.children
            ? []
            : topic.children.map((child) => {
                return {
                  value: `${child.id}`,
                  label: child.title,
                };
              }),
        };
      });
      return options;
    }
    return [];
  };

  // Action Handlers
  const onChange = (field: string, state: any) => {
    const { setActivity } = props;
    setActivity(state);
  };

  const onIntroChange = (intro: string) => {
    onChange('intro', {
      intro: updateCmsEditorBeforeSave(intro),
    });
  };

  // Custom Field Validators
  const validateIntro = (rule, value) => {
    const { activity } = props;
    const { intro } = activity;
    try {
      if (intro.length === 0) {
        throw new Error('Introduction is required.');
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  // This validator is a hotfix to resolve an issue where
  // navigating to another form step and back causes the
  // description field in the form to be undefined.
  const validateDescription = (rule, value) => {
    const { activity } = props;
    const { description } = activity;
    try {
      if (description.length === 0) {
        throw new Error('Description is required.');
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const validateTitle = (rule, value) => {
    const { activity } = props;
    const { title } = activity;
    try {
      if (title.length === 0) {
        throw new Error('Title is required.');
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  // Renderers
  const renderType = (value: string) => {
    switch (value) {
      case 'quiz':
        return 'Quizzes';
      case 'category':
        return 'Break it down';
      case 'category no-answer':
        return 'Break it down (no answer)';
      case 'cyoa':
        return 'Call the shots';
      case 'fill in the blank':
        return 'Fill it in';
      case 'goals':
        return 'Goals';
      case 'ranking':
        return 'Sort it out';
      case 'screener':
        return 'Assessments';
    }
  };

  const characterLimit = (s: string, limit: number): number => {
    return s ? (s.length < limit ? limit - s.length : 0) : 0;
  };

  const handleTopicChange = (values: any) => {
    if (values.length > 0) {
      const selectedTopicId = +values[0];
      const selectedSubTopicId = +values[1];
      setState({
        ...state,
        selectedTopicId,
        selectedSubTopicId,
        showTopicError: selectedSubTopicId === -1,
      });
      onChange('topicId', {
        topicId: selectedSubTopicId,
      });
    }
  };

  const cascadeTopics = getResourceTopicsCascader(topics.data);

  const { activity, types, creative, editable } = props;
  const { title, description, prompt, intro } = activity;
  return (
    <div className="activity-form">
      <Form ref={form} key="form" layout="vertical" colon={false}>
        {(topics.isLoading || !state.isLoaded) && <Spin />}
        {!topics.isLoading && state.isLoaded && (
          <>
            <Item
              key="typeId"
              name="typeId"
              label={
                <ActivityItemLabel
                  label="Activity Type:"
                  tooltip="Type determines what kind of activity you will create."
                />
              }
              style={itemStyle}
              rules={[{ required: true, message: 'Type is required.' }]}
              initialValue={activity.typeId <= 0 ? undefined : activity.typeId}
            >
              <Select
                disabled={!creative && !editable}
                onChange={(val) => onChange('typeId', { typeId: val })}
                placeholder="Type"
                style={{ width: 220 }}
              >
                {types?.map((t) => (
                  <Option key={t.id} value={t.id}>
                    {renderType(t.type)}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              label="Topic:"
              key="topicId"
              name="topicId"
              style={itemStyle}
              rules={[{ required: true, message: 'Topic is required.' }]}
              initialValue={
                state.selectedTopicId <= 0 && state.selectedSubTopicId <= 0
                  ? undefined
                  : [
                      `${
                        state.selectedTopicId <= 0 ? '' : state.selectedTopicId
                      }`,
                      `${
                        state.selectedSubTopicId <= 0
                          ? ''
                          : state.selectedSubTopicId
                      }`,
                    ]
              }
            >
              <Cascader
                className={`hmp-resource-editor-topic-selector ${
                  state.showTopicError ? 'error' : ''
                }`}
                disabled={!creative && !editable}
                options={cascadeTopics}
                dropdownClassName="hmp-resource-editor-topic-selector-popup-menu"
                changeOnSelect
                onChange={handleTopicChange}
              />
            </Item>
            <Item
              key="title"
              name="title"
              label="Title:"
              style={itemStyle}
              rules={[
                {
                  required: true,
                  message: 'Title is required.',
                  validator: validateTitle,
                },
              ]}
              initialValue={title}
            >
              <>
                <Input
                  spellCheck
                  disabled={!creative && !editable}
                  placeholder="Name the activity"
                  maxLength={200}
                  defaultValue={title}
                  onChange={(e) => onChange('title', { title: e.target.value })}
                />
                <span>({characterLimit(title, 200)} characters left.)</span>
              </>
            </Item>
            <Item
              key="description"
              name="description"
              label="Description:"
              style={itemStyle}
              rules={[{ required: true, validator: validateDescription }]}
            >
              <>
                <Input
                  spellCheck
                  disabled={!creative && !editable}
                  placeholder="Blurb shown on main list"
                  maxLength={400}
                  defaultValue={description}
                  onChange={(e) =>
                    onChange('description', {
                      description: e.target.value,
                    })
                  }
                />
                <span>
                  ({characterLimit(description, 400)} characters left.)
                </span>
              </>
            </Item>
            <Item
              key="prompt"
              name="prompt"
              label={
                <ActivityItemLabel
                  label="Prompt:"
                  tooltip="The text that appears on the screen with each question."
                />
              }
              style={itemStyle}
            >
              <>
                <Input
                  spellCheck
                  disabled={!creative && !editable}
                  placeholder="Prompt to appear above questions"
                  maxLength={200}
                  defaultValue={prompt}
                  onChange={(e) =>
                    onChange('prompt', { prompt: e.target.value })
                  }
                />
                <span>({characterLimit(prompt, 200)} characters left.)</span>
              </>
            </Item>
            <Item
              key="intro"
              name="intro"
              label="Introduction:"
              style={itemStyle}
              rules={[{ required: true, validator: validateIntro }]}
              valuePropName="model"
              initialValue={intro}
            >
              <FroalaCustomUpload
                disabled={!creative && !editable}
                onChange={onIntroChange}
              />
            </Item>
          </>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    categories: selectors.getCategories(state),
    types: selectors.getTypes(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadCategories: () => dispatch(getActivityCategoriesAsync.request()),
    loadTypes: () => dispatch(getActivityTypesAsync.request()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDetailForm);
