import { Button, Card, Modal, Spin, Table } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getExpertProfiles } from '../../../service/configService';
import { ExpertProfileType } from '../../../types/serverTypes/qnaTypes';
import ExpertProfileEdit from '../../../components/config/ExpertProfileEdit';
import { PictureOutlined } from '@ant-design/icons';
import { getStudyId } from '../../../service/studyService';

const ExpertProfileConfig = () => {
  const [isDetailVisible, setIsDetailVisible] = useState<boolean>(false);
  const [expertProfile, setExpertProfile] = useState<ExpertProfileType>();

  const expertProfiles = useQuery<ExpertProfileType[]>(
    'expertProfiles',
    getExpertProfiles
  );

  const onProfileClick = (profile: ExpertProfileType) => {
    setExpertProfile(profile);
    setIsDetailVisible(true);
  };
  const onCloseDetail = () => {
    setIsDetailVisible(false);
  };
  const showAddProfileModal = () => {
    setExpertProfile({
      id: 0,
      profileDescription: '',
      profileUri: '',
      studyId: getStudyId(),
    });
    setIsDetailVisible(true);
  };
  if (expertProfiles.isLoading) {
    return <Spin />;
  }
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (text, record) => (
        <a onClick={() => onProfileClick(record)} style={{ width: 100 }}>
          {text}
        </a>
      ),
    },
    {
      title: 'Photo',
      dataIndex: 'profileUri',
      key: 'uri',
      width: 120,
      render: (text, record) => {
        if (text) {
          return <img src={text} width={100} height={100} />;
        } else {
          return <PictureOutlined style={{ fontSize: 20 }} />;
        }
      },
    },
    {
      title: 'Description',
      dataIndex: 'profileDescription',
      key: 'description',
    },
  ];
  return (
    <Card
      title={
        <div className="badges-title">
          <h1>Expert Profiles</h1>
          <Button type="primary" onClick={showAddProfileModal}>
            {' '}
            + Add New Profile
          </Button>
        </div>
      }
    >
      <div>
        <Table dataSource={expertProfiles.data} columns={columns} rowKey="id" />
        <Modal
          title={expertProfile?.id ? 'Edit Profile' : 'Add Profile'}
          visible={isDetailVisible}
          destroyOnClose={true}
          footer={null}
          width={800}
        >
          {expertProfile && (
            <ExpertProfileEdit
              profile={expertProfile}
              onClose={onCloseDetail}
            />
          )}
        </Modal>
      </div>
    </Card>
  );
};

export default ExpertProfileConfig;
