import { Alert, Button, Checkbox, Input, message, Spin } from 'antd';
import * as _ from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import ReactTelInput from 'react-international-telephone-input/lib/withStyles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { getStudyArmLabelFromId } from '../../../components/util/Util';
import * as selectors from '../../../redux/selectors';
import {
  PasswordArguments,
  updateEmailAsync,
  updateMobileAsync,
  updatePasswordAsync,
} from '../../../redux/user/user.types';
import IApplicationState from '../../../types/state.types';
import { IApiRequestStatus } from '../../../types/api.types';
import { clearStatus } from '../../../redux/api/api.types';
import './settings.scss';
import { getType } from 'typesafe-actions';
import {
  ParticipantType,
  StudyArmType,
} from '../../../types/serverTypes/studyTypes';
import { AdminUserType } from '../../../types/serverTypes/adminTypes';
import AdminNotificationSetting from '../../../components/notification/AdminNotificationSetting';
import { TimeZoneColorStyles } from '../../../styles/styles';
import TimezoneSelect from 'react-timezone-select';
import { useMutation, useQuery } from 'react-query';
import { updateTimezone } from '../../../service/usmgService';
import { getAppLookups } from '../../../service/lookupService';
const flagsImagePath = require('../../../assets/images/flags.png');
var momentzone = require('moment-timezone');

interface StateProps {
  user: Optional<AdminUserType>;
  pseudoParticipants: Optional<ParticipantType[]>;
  studyArms: Optional<StudyArmType[]>;
  studyId: number;
  updatePasswordStatus: IApiRequestStatus;
  isNonYAB: boolean;
}

interface DispatchProps {
  updatePassword: typeof updatePasswordAsync.request;
  updateEmail: typeof updateEmailAsync.request;
  updateMobile: typeof updateMobileAsync.request;
  clearStatus: typeof clearStatus;
}

interface ComponentProps extends DispatchProps, StateProps {}

const initialState = {
  user: undefined as Optional<AdminUserType>,
  pseudoParticipants: [] as Optional<ParticipantType[]>,
  editMobileVisible: false as boolean,
  editEmailVisible: false as boolean,
  editPasswordVisible: false as boolean,
  editTimezoneVisible: false as boolean,
  mobile: '' as Optional<string>,
  email: '' as Optional<string>,
  password: '' as Optional<string>,
  password2x: '' as Optional<string>,
  currentPassword: '' as Optional<string>,
  updatePseudoParticipants: false as boolean,
  passwordMessage: 'Password is required ' as Optional<string>,
};

type ComponentState = typeof initialState;

const SettingsLandingPage = (props: ComponentProps) => {
  const [state, setState] = useState<ComponentState>(initialState);

  const [selectedTimezone, setSelectedTimezone] = useState<any>(
    props.user?.timeZone
  );
  const setTimeZone = (timeZoneValue) => {
    setSelectedTimezone(timeZoneValue.value);
  };
  const appLookups = useQuery('applookups', () => getAppLookups());

  useEffect(() => {
    setState({
      ...state,
      mobile: props.user?.mobile,
      email: props.user?.email,
    });
  }, []);

  const updateMobileInState = (mobile, countryCode) => {
    setState({ ...state, mobile });
  };

  const cancelSaveMobile = () => {
    const mobile = props.user?.mobile ? props.user.mobile : '';

    setState({ ...state, mobile, editMobileVisible: false });
  };

  const saveMobile = () => {
    const { mobile } = state;
    props.updateMobile(mobile);
    setState({ ...state, editMobileVisible: false });
  };

  const updateEmailInState = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, email: event.target.value });
  };

  const cancelSaveEmail = () => {
    setState({ ...state, editEmailVisible: false, email: props.user?.email });
  };

  const saveEmail = () => {
    props.updateEmail(state.email);
    setState({ ...state, editEmailVisible: false });
  };

  const cancelSaveTimezone = () => {
    setState({ ...state, editTimezoneVisible: false });
  };

  const updateTimezoneMutation = useMutation(updateTimezone, {
    onError: (error, variables, context) => {
      message.error('Error in updating time zone.');
    },
    onSuccess: (data, variables, context) => {
      message.success('Time zone updated successfully.');
    },
  });

  const saveTimezone = () => {
    // props.updateEmail(state.email);
    setState({ ...state, editTimezoneVisible: false });
    updateTimezoneMutation.mutate(selectedTimezone);
  };

  const updatePasswordInState = (event: ChangeEvent<HTMLInputElement>) => {
    const { password2x } = state;
    let passwordMessage;
    if (!event.target.value) {
      passwordMessage = 'Password is required';
    } else if (event.target.value.length < 8) {
      passwordMessage = 'Password must be at least 8 characters';
    } else if (event.target.value !== password2x) {
      passwordMessage = 'Passwords should match';
    }
    setState({ ...state, password: event.target.value, passwordMessage });
  };

  const updatePassword2xInState = (event: ChangeEvent<HTMLInputElement>) => {
    const { password } = state;
    let passwordMessage;
    if (!event.target.value) {
      passwordMessage = 'Password is required';
    } else if (event.target.value !== password) {
      passwordMessage = 'Passwords should match';
    }
    setState({ ...state, password2x: event.target.value, passwordMessage });
  };

  const onCurrentPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, currentPassword: event.target.value });
  };

  const onUpdatePseudoParticipantsChange = (event: CheckboxChangeEvent) => {
    setState({ ...state, updatePseudoParticipants: event.target.checked });
  };

  const cancelSavePassword = () => {
    setState({
      ...state,
      password: '',
      password2x: '',
      passwordMessage: undefined,
      editPasswordVisible: false,
    });
  };

  const savePassword = () => {
    const { password, password2x, currentPassword, updatePseudoParticipants } =
      state;
    const { updatePassword } = props;
    if (password && password === password2x && currentPassword) {
      updatePassword({ password, currentPassword, updatePseudoParticipants });
      setState({
        ...state,
        editPasswordVisible: false,
        password: '',
        currentPassword: '',
        password2x: '',
        updatePseudoParticipants: false,
      });
    }
  };

  const notificationOrder = [
    'NEW_MESSAGE',
    'NEW_MESSAGE_IN_MY_THREAD',
    'THREAD_ASSIGNED_TO_ME',
    'NEW_ASK_THE_EXPERT',
  ];

  const {
    user,
    pseudoParticipants,
    studyArms,
    studyId,
    updatePasswordStatus,
    clearStatus,
    isNonYAB,
  } = props;

  const {
    editMobileVisible,
    mobile,
    editEmailVisible,
    email,
    editPasswordVisible,
    password,
    password2x,
    passwordMessage,
    currentPassword,
    updatePseudoParticipants,
    editTimezoneVisible,
  } = state;

  if (!user) {
    return <div> loading</div>;
  }

  if (updatePasswordStatus.isSuccess) {
    message.success('Password updated successfully.');
    clearStatus(getType(updatePasswordAsync.success));
  } else if (updatePasswordStatus.isError) {
    if (updatePasswordStatus.errorStatus === 401) {
      message.error('Current password was incorrect.', 3);
    } else {
      message.error(updatePasswordStatus.errorMessage);
    }
    clearStatus(getType(updatePasswordAsync.failure));
  }

  let { notificationSubscriptions } = user;

  notificationSubscriptions = _.sortBy(notificationSubscriptions, (ns) =>
    _.indexOf(notificationOrder, ns.label)
  );

  const showMobileInputField = () => {
    setState({ ...state, editMobileVisible: true });
  };

  const showEmailInputField = () => {
    setState({ ...state, editEmailVisible: true });
  };

  const showPasswordInputField = () => {
    setState({ ...state, editPasswordVisible: true });
  };

  const showTimezoneInputField = () => {
    setState({ ...state, editTimezoneVisible: true });
  };
  if (appLookups.isLoading) {
    return <Spin />;
  }

  const defaultCountryCode =
    (appLookups.data ? appLookups.data['phone_default_country_code'] : 'us') ??
    'us';

  return (
    <div id="settings">
      <div className="titleRow">
        <h3>Details</h3>
      </div>
      <div className="details indent">
        <div className="settings-column">
          <p>
            Id:
            {user?.id}
          </p>
          {editMobileVisible ? (
            <span className="editable-field">
              <ReactTelInput
                value={mobile}
                flagsImagePath={flagsImagePath}
                onChange={updateMobileInState}
                defaultCountry={defaultCountryCode}
              />
              <div>
                <Button className="settings-button" onClick={saveMobile}>
                  Save
                </Button>
                <Button className="settings-button" onClick={cancelSaveMobile}>
                  Cancel
                </Button>
              </div>
            </span>
          ) : (
            <span className="editable-field">
              <p>
                Mobile:
                {mobile || '(none)'}
              </p>
              <Button
                className="settings-button"
                onClick={showMobileInputField}
              >
                Edit
              </Button>
            </span>
          )}
          {editEmailVisible ? (
            <span className="editable-field">
              <Input key="email" onChange={updateEmailInState} value={email} />
              <Button className="settings-button" onClick={saveEmail}>
                Save
              </Button>
              <Button className="settings-button" onClick={cancelSaveEmail}>
                Cancel
              </Button>
            </span>
          ) : (
            <span className="editable-field">
              <p>
                Email:
                {email || '(none)'}
              </p>
              <Button className="settings-button" onClick={showEmailInputField}>
                Edit
              </Button>
            </span>
          )}
          {editPasswordVisible ? (
            <span className="editable-field active">
              Current Password:{' '}
              <Input.Password
                visibilityToggle
                key="current-password"
                onChange={onCurrentPasswordChange}
                value={currentPassword}
              />
              New Password:{' '}
              <Input.Password
                visibilityToggle
                key="pwd1"
                onChange={updatePasswordInState}
                value={password}
              />
              Confirm New Password:{' '}
              <Input.Password
                visibilityToggle
                key="pwd2"
                onChange={updatePassword2xInState}
                value={password2x}
              />
              <Checkbox
                onChange={onUpdatePseudoParticipantsChange}
                checked={updatePseudoParticipants}
              >
                Also update pseudo-participant passwords associated with your
                user?
              </Checkbox>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingTop: '5px',
                  alignContent: 'space-between',
                  width: '100%',
                }}
              >
                {passwordMessage ? (
                  <Alert
                    type="error"
                    message={passwordMessage}
                    style={{ height: 32 }}
                  />
                ) : (
                  <span>
                    <Button className="settings-button" onClick={savePassword}>
                      Save
                    </Button>
                  </span>
                )}
                <Button
                  className="settings-button"
                  onClick={cancelSavePassword}
                >
                  Cancel
                </Button>
              </div>
            </span>
          ) : (
            <span className="editable-field">
              <p>Password: ****************** </p>
              <Button
                className="settings-button"
                onClick={showPasswordInputField}
              >
                Edit
              </Button>
            </span>
          )}
          {editTimezoneVisible ? (
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div>
                <TimezoneSelect
                  value={selectedTimezone}
                  onChange={setTimeZone}
                  styles={TimeZoneColorStyles}
                  labelStyle="abbrev"
                />
              </div>
              <div>
                <Button className="settings-button" onClick={saveTimezone}>
                  Save
                </Button>
                <Button
                  className="settings-button"
                  onClick={cancelSaveTimezone}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <span className="editable-field">
              <p>
                Time Zone: {selectedTimezone} (
                {momentzone().tz(selectedTimezone).format('z')}){' '}
              </p>
              <Button
                className="settings-button"
                onClick={showTimezoneInputField}
              >
                Edit
              </Button>
            </span>
          )}
        </div>
      </div>
      <div>
        <div className="titleRow">
          <h3>Roles</h3>
        </div>
        <div>
          {_.map(user.roles, (role) => {
            return (
              <div className="details indent" key={role.role.role}>
                <div className="settings-column">
                  <p>
                    {role.role.role} : {role.role.description}{' '}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {pseudoParticipants?.length ? (
        <div>
          <div className="titleRow">
            <h3>Pseudo Participants</h3>
          </div>
          <div className="tableRow">
            <table id="pseudos-table">
              <thead>
                <tr style={{ borderBottom: '1px solid #8185B3' }}>
                  <th>Username</th>
                  <th>Study Arm</th>
                  <th>Id</th>
                </tr>
              </thead>
              <tbody>
                {_.map(pseudoParticipants, (p) => {
                  return p ? (
                    <tr key={p.id}>
                      <td>
                        <a href={`/study/${studyId}/participants/${p.id}`}>
                          {p.username}
                        </a>
                      </td>
                      <td>{getStudyArmLabelFromId(studyArms, p.studyArmId)}</td>
                      <td>{p.id}</td>
                    </tr>
                  ) : undefined;
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : undefined}

      {isNonYAB ? <AdminNotificationSetting /> : null}
    </div>
  );
};

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    user: selectors.getUser,
    pseudoParticipants: selectors.getCurrentUserPseudoParticipants,
    studyArms: selectors.getStudyArms,
    studyId: selectors.getRequestedStudyId,
    updatePasswordStatus: selectors.updatePasswordStatus,
    isNonYAB: selectors.isNonYAB,
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    updatePassword: (args: PasswordArguments) =>
      dispatch(updatePasswordAsync.request(args)),
    updateMobile: (mobile: Optional<string>) =>
      dispatch(updateMobileAsync.request(mobile)),
    updateEmail: (email: Optional<string>) =>
      dispatch(updateEmailAsync.request(email)),
    clearStatus: (type: string) => dispatch(clearStatus(type)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettingsLandingPage)
);
