import { Button, Modal, Spin, Table, Typography } from 'antd';
import { renderDateWithTime } from '../../util/Util';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getParticipantPlans } from '../../../service/planService';
import { PlanType } from '../../../types/serverTypes/planTypes';
import ParticipantPlanDetailEdit from './ParticipantPlanDetailEdit';
import '../Plan.scss';
import AssociatePlan from './AssociatePlan';
interface ParticipantPlanDetailProp {
  participantId: number;
}
const ParticipantPlanDetail = (props: ParticipantPlanDetailProp) => {
  const [showPlanDetail, setShowPlanDetail] = useState<boolean>(false);
  const [showAssociatedPlan, setShowAssociatedPlan] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanType>();
  const participantPlans = useQuery('participantPlans', () =>
    getParticipantPlans(props.participantId)
  );
  const queryClient = useQueryClient();

  const onPlanClick = (plan) => {
    setSelectedPlan(plan);
    setShowPlanDetail(true);
  };
  const onEditDone = () => {
    setShowPlanDetail(false);
  };
  const onAddDone = () => {
    // reset the query
    queryClient.invalidateQueries('participantPlans');
    setShowAssociatedPlan(false);
  };
  const onAddCancel = () => {
    setShowAssociatedPlan(false);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <a onClick={() => onPlanClick(record)}>{text}</a>
      ),
    },
    {
      title: 'Create Date',
      dataIndex: 'createDate',
      key: 'createDate',
      render: renderDateWithTime,
    },
  ];
  const onAssociatePlan = () => {
    setShowAssociatedPlan(true);
  };

  if (participantPlans.isLoading) {
    return <Spin />;
  }
  const getAssociatePlanModal = () => (
    <Modal
      title="Add Plan"
      visible={showAssociatedPlan}
      destroyOnClose={true}
      width={800}
      footer={null}
    >
      <AssociatePlan
        participantId={props.participantId}
        onDone={onAddDone}
        onCancel={onAddCancel}
      />
    </Modal>
  );
  if (participantPlans.data?.length === 0) {
    return (
      <>
        <div style={{ marginBottom: 10 }}>
          <Typography.Text>
            No plan is associated with this participant.
          </Typography.Text>
        </div>
        <div className="button-container-left">
          <Button onClick={onAssociatePlan}>Add Plan</Button>
        </div>
        {getAssociatePlanModal()}
      </>
    );
  }
  return (
    <div>
      <div className="button-container">
        <Button onClick={onAssociatePlan}>Add Plan</Button>
      </div>
      <Table dataSource={participantPlans.data} columns={columns} rowKey="id" />
      {selectedPlan && (
        <Modal
          title={selectedPlan?.title}
          visible={showPlanDetail}
          destroyOnClose={true}
          width={800}
          footer={null}
        >
          <ParticipantPlanDetailEdit
            plan={selectedPlan}
            participantId={props.participantId}
            onDone={onEditDone}
          />
        </Modal>
      )}
      {getAssociatePlanModal()}
    </div>
  );
};

export default ParticipantPlanDetail;
