import { Button, message, Select, Spin } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getParticipantPlans, getPlans } from '../../../service/planService';
import { PlanType } from '../../../types/serverTypes/planTypes';
import '../Plan.scss';

interface SelectPlanProp {
  onCancel: () => void;
  onNext: (plan: PlanType) => void;
  participantId: number;
}

const SelectPlan = (props: SelectPlanProp) => {
  const plans = useQuery<PlanType[]>('plans', () => getPlans());
  const participantPlans = useQuery('participantPlans', () =>
    getParticipantPlans(props.participantId)
  );

  const [selectedPlan, setSelectedPlan] = useState<PlanType>();

  const onNext = () => {
    if (selectedPlan) {
      props.onNext(selectedPlan);
    } else {
      message.error('Please select a plan');
    }
  };

  const onPlanSelect = (value) => {
    const planPicked = plans.data?.find((p) => p.id === value);
    if (planPicked) {
      setSelectedPlan(planPicked);
    }
  };

  if (plans.isLoading || participantPlans.isLoading) {
    return <Spin />;
  }
  if (plans.data?.length === 0) {
    return <div>No plan found. Please create a new plan first.</div>;
  }

  return (
    <div className="participant-steps-content">
      <Select onChange={onPlanSelect} style={{ width: 250 }}>
        {plans.data
          ?.filter(
            (plan) =>
              participantPlans.data?.findIndex((pp) => pp.id === plan.id) === -1
          )
          .map((plan) => {
            return (
              <Select.Option key={plan.id} value={plan.id}>
                {plan.title}
              </Select.Option>
            );
          })}
      </Select>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        <Button onClick={props.onCancel} style={{ marginRight: 10 }}>
          Cancel
        </Button>
        <Button onClick={onNext} type="primary">
          Next
        </Button>
      </div>
    </div>
  );
};

export default SelectPlan;
