import Layout from 'antd/lib/layout';
import Spin from 'antd/lib/spin';
import Typography from 'antd/lib/typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import Header from '../components/header/Header';
import StudySideBar from '../components/sidebar/StudySideBar';
import StudyNotFound from '../pages/404/StudyNotFound';
import AnalyticsReport from '../pages/reports/AnalyticsReport';
import IncentivesReport from '../pages/reports/IncentivesReport';
import ActivitiesLandingPage from '../pages/study/activity/ActivitiesLandingPage';
import AdHocQueryLandingPage from '../pages/study/adHocQuery/AdHocQueryLandingPage';
import ArmsPage from '../pages/study/arm/ArmsPage';
import DashboardLandingPage from '../pages/study/dashboard/DashboardLandingPage';
import Form from '../pages/study/form/Form';
import ForumPage from '../components/forum/ForumPage';
import Study from '../pages/study/home/Study';
import PaymentsLandingPage from '../pages/study/payment/PaymentsLandingPage';
import Participant from '../pages/study/participant/detail/Participant';
import ParticipantsScreen from '../pages/study/participant/search/ParticipantsScreen';
import ResourceEditPage from '../pages/study/resource/ResourceEditPage';
import ResourcesLandingPage from '../pages/study/resource/ResourcesLandingPage';
import SettingsLandingPage from '../pages/study/settings/SettingsLandingPage';
import SurveysLandingPage from '../pages/study/survey/SurveysLandingPage';
import TestKitsLandingPage from '../pages/study/testkit/TestKitsLandingPage';
import { loadForumTopicsAsync } from '../redux/topics/topic.types';
import * as selectors from '../redux/selectors';
import { loadStudyAsync } from '../redux/study/study.types';
import { IApiRequestState } from '../types/api.types';
import IApplicationState from '../types/state.types';
import Avatars from '../components/avatar/Avatars';
import Inbox from '../pages/study/inbox/Inbox';
import AnnouncementLandingPage from '../pages/study/announcement/AnnouncementLandingPage';
import QnaLandingPage from '../pages/study/qna/QnaLandingPage';
import UserCreationPage from '../pages/study/user/UserCreationPage';
import GoalSettingLandingPage from '../pages/study/goalSetting/GoalSettingLandingPage';
import BadgesLandingPage from '../components/badge/BadgesLandingPage';
import { StudyType } from '../types/serverTypes/studyTypes';
import { TopicType } from '../types/serverTypes/forumTypes';
import AdminManagementLandingPage from '../pages/study/admin/AdminManagementLandingPage';
import AppointmentAvailabilityLandingPage from '../pages/study/appointment/AppointmentAvailabilityLandingPage';
import NotificationLandingPage from '../pages/study/notification/NotificationLandingPage';
import FeatureSetting from '../pages/study/feature/FeatureSetting';
import StringLookupLandingPage from '../pages/study/stringLookup/StringLookupLandingPage';
import AvatarCustomLanding from '../pages/study/avatar/AvatarCustomLanding';
import PlanLandingPage from '../pages/study/plan/PlanLandingPage';
import EmailTemplateConfig from '../pages/study/config/EmailTemplateConfig';
import ExpertProfileConfig from '../pages/study/config/ExpertProfileConfig';
import ForumAddBulk from '../pages/study/forum/ForumAddBulk';

const { Content, Footer } = Layout;

const { Title } = Typography;

interface StateProps {
  requestedStudyId: number;
  study: Optional<StudyType>;
  status: IApiRequestState;
  resourceTopics: Optional<TopicType[]>;
  isNonYAB: boolean;
  hasDataAnalystRole: boolean;
  hasStudyManagerRole: boolean;
  showGoals: boolean;
  showIncentives: boolean;
  showForms: boolean;
  showSurveys: boolean;
  showTestKits: boolean;
  showAppointmentAvailability: boolean;
  showAppointments: boolean;
  showAvatarCustom: boolean;
  showQna: boolean;
}

interface DispatchProps {
  loadStudy: typeof loadStudyAsync.request;
  loadForumTopics: typeof loadForumTopicsAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {}

class StudyRouter extends Component<ComponentProps, {}> {
  componentDidMount(): void {
    const { loadStudy, loadForumTopics, requestedStudyId } = this.props;
    loadStudy(requestedStudyId);
  }

  render() {
    const {
      requestedStudyId,
      study,
      status,
      isNonYAB,
      hasDataAnalystRole,
      hasStudyManagerRole,
      showSurveys,
      showForms,
      showGoals,
      showIncentives,
      showTestKits,
      showAppointmentAvailability,
      showAvatarCustom,
      showAppointments,
      showQna,
    } = this.props;

    // TODO componentize the Spinner and Error Message

    if (status.isLoading) {
      return (
        <Layout id="main">
          <Header />
          <Content id="content">
            <Spin size="large" />
          </Content>
        </Layout>
      );
    }

    if (status.isError) {
      return (
        <Layout id="main">
          <Header />
          <Content id="content">Error: {status.errorMessage}</Content>
        </Layout>
      );
    }

    if (requestedStudyId === -1) {
      return (
        <Layout id="main">
          <Header />
          <Content id="content">
            An invalid or unknown Study ID was specified in the URL.
          </Content>
        </Layout>
      );
    }

    if (!study) {
      return (
        <Layout id="main">
          <Header />
          <Content id="content">The Study could not be loaded.</Content>
        </Layout>
      );
    }

    const fullPrivilegeRoutes: JSX.Element[] = [];
    const dataAnalystRoutes: JSX.Element[] = [];

    if (isNonYAB) {
      fullPrivilegeRoutes.push(
        <Route
          key="dashboard"
          exact
          path="/study/:studyId"
          component={DashboardLandingPage}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="study"
          exact
          path="/study/:studyId/study"
          component={Study}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="activities"
          exact
          path="/study/:studyId/activities"
          component={ActivitiesLandingPage}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="announcements"
          exact
          path="/study/:studyId/announcements"
          component={AnnouncementLandingPage}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="arms"
          exact
          path="/study/:studyId/arms"
          component={ArmsPage}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="avatars"
          exact
          path="/study/:studyId/avatars"
          component={showAvatarCustom ? AvatarCustomLanding : Avatars}
        />
      );

      fullPrivilegeRoutes.push(
        <Route
          key="badges"
          path="/study/:studyId/badges"
          component={BadgesLandingPage}
        />
      );

      fullPrivilegeRoutes.push(
        <Route
          key="inbox"
          exact
          path="/study/:studyId/inbox"
          component={Inbox}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="participants"
          exact
          path="/study/:studyId/participants"
          component={ParticipantsScreen}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="participants"
          exact
          path="/study/:studyId/participants/flagged"
          component={ParticipantsScreen}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="participant"
          path="/study/:studyId/participants/:participantId"
          component={Participant}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="qna"
          exact
          path="/study/:studyId/qna"
          component={QnaLandingPage}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="resources"
          exact
          path="/study/:studyId/resources"
          component={ResourcesLandingPage}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="newResource"
          exact
          path="/study/:studyId/resources/new"
          component={ResourceEditPage}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="editResource"
          exact
          path="/study/:studyId/resources/edit/:articleId"
          component={ResourceEditPage}
        />
      );
      fullPrivilegeRoutes.push(
        <Route
          key="user creation"
          exact
          path="/study/:studyId/user"
          component={UserCreationPage}
        />
      );

      if (hasStudyManagerRole) {
        fullPrivilegeRoutes.push(
          <Route
            key="admin"
            exact
            path="/study/:studyId/admin"
            component={AdminManagementLandingPage}
          />,
          <Route
            key="notification"
            exact
            path="/study/:studyId/notification"
            component={NotificationLandingPage}
          />,
          <Route
            key="notification/:subpage/:id"
            exact
            path="/study/:studyId/notification/:subpage/:id?"
            component={NotificationLandingPage}
          />,
          <Route
            key="features"
            exact
            path="/study/:studyId/features"
            component={FeatureSetting}
          />,
          <Route
            key="stringLookups"
            exact
            path="/study/:studyId/stringLookups"
            component={StringLookupLandingPage}
          />
        );
      }

      if (showAppointments) {
        fullPrivilegeRoutes.push(
          <Route
            key="appointments"
            path="/study/:studyId/appointments"
            component={AppointmentAvailabilityLandingPage}
          />
        );
      }

      fullPrivilegeRoutes.push(
        <Route
          key="plan"
          path="/study/:studyId/plan"
          component={PlanLandingPage}
        />
      );

      if (showGoals) {
        fullPrivilegeRoutes.push(
          <Route
            key="goals"
            exact
            path="/study/:studyId/goals"
            component={GoalSettingLandingPage}
          />
        );
        fullPrivilegeRoutes.push(
          <Route
            key="viewGoal"
            exact
            path="/study/:studyId/goals/:subpage/:id?"
            component={GoalSettingLandingPage}
          />
        );
      }

      if (showForms) {
        fullPrivilegeRoutes.push(
          <Route
            key="forms"
            exact
            path="/study/:studyId/form"
            component={Form}
          />
        );
      }
      if (showIncentives) {
        fullPrivilegeRoutes.push(
          <Route
            key="payments"
            exact
            path="/study/:studyId/payments"
            component={PaymentsLandingPage}
          />
        );
      }
      if (showSurveys) {
        fullPrivilegeRoutes.push(
          <Route
            key="surveys"
            exact
            path="/study/:studyId/surveys"
            component={SurveysLandingPage}
          />
        );
      }
      if (showTestKits) {
        fullPrivilegeRoutes.push(
          <Route
            key="testkits"
            path="/study/:studyId/testkits"
            component={TestKitsLandingPage}
          />
        );
      }
    }

    if (hasDataAnalystRole) {
      if (showIncentives) {
        dataAnalystRoutes.push(
          <Route
            key="incentives_report"
            exact
            path="/study/:studyId/reports/incentives"
            component={IncentivesReport}
          />
        );
      }
      dataAnalystRoutes.push(
        <Route
          key="analytics_report"
          exact
          path="/study/:studyId/reports/analytics"
          component={AnalyticsReport}
        />
      ); // todo this report is broken, removed from StudySideBar currently
      dataAnalystRoutes.push(
        <Route
          key="ad hoc query"
          exact
          path="/study/:studyId/adHocQuery"
          component={AdHocQueryLandingPage}
        />
      );
    }

    fullPrivilegeRoutes.push(
      <Route
        key="settings"
        exact
        path="/study/:studyId/settings"
        component={SettingsLandingPage}
      />
    );
    fullPrivilegeRoutes.push(
      <Route
        key="emailTemplates"
        exact
        path="/study/:studyId/emailTemplates"
        component={EmailTemplateConfig}
      />
    );
    if (showQna) {
      fullPrivilegeRoutes.push(
        <Route
          key="expertProfiles"
          exact
          path="/study/:studyId/expertProfiles"
          component={ExpertProfileConfig}
        />
      );
    }
    fullPrivilegeRoutes.push(
      <Route
        key="forumAddBulk"
        exact
        path="/study/:studyId/forums/addBulk"
        component={ForumAddBulk}
      />
    );

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <StudySideBar />
        <Layout id="main">
          <Header>
            <span style={{ paddingLeft: '10px', fontSize: '25px' }}>
              {study?.name}
            </span>
          </Header>

          <Content id="content" style={{ height: '100vh - 70px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ flex: '1 1 auto', height: '100%' }}>
                <Switch>
                  {...fullPrivilegeRoutes}
                  {...dataAnalystRoutes}
                  <Route
                    key="forums"
                    path="/study/:studyId/forums"
                    component={ForumPage}
                  />
                  <Route key="notFound" component={StudyNotFound} />
                </Switch>
              </div>
            </div>
          </Content>
          {/* <Footer>Footer</Footer> */}
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    requestedStudyId: selectors.getRequestedStudyId,
    study: selectors.getRequestedStudy,
    status: selectors.loadStudyStatus,
    resourceTopics: selectors.getResourceTopics,
    isNonYAB: selectors.isNonYAB,
    hasDataAnalystRole: selectors.hasDataAnalystRole,
    hasStudyManagerRole: selectors.hasStudyManagerRole,
    showGoals: selectors.getGoalSettingFeatureEnabled,
    showIncentives: selectors.getIncentivesFeatureEnabled,
    showForms: selectors.getFormsFeatureEnabled,
    showSurveys: selectors.getSurveysFeatureEnabled,
    showTestKits: selectors.getTestKitsFeatureEnabled,
    showAppointments: selectors.getAppointmentsFeatureEnabled,
    showAvatarCustom: selectors.getAvatarCustomFeatureEnabled,
    showAppointmentAvailability:
      selectors.getAppointmentAvailabilityFeatureEnabled,
    showQna: selectors.getQnaFeatureEnabled,
  }
);

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadStudy: (studyId: number) => dispatch(loadStudyAsync.request(studyId)),
    loadForumTopics: (forumId: number) =>
      dispatch(loadForumTopicsAsync.request(forumId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyRouter);
