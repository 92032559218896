import { Button, Card, message, Modal, Popconfirm, Table } from 'antd';
import { renderDateWithTime } from '../../../components/util/Util';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getPlans } from '../../../service/planService';
import { PlanType } from '../../../types/serverTypes/planTypes';
import './planLandingPage.scss';
import PlanNew from './PlanNew';
import PlanDetail from '../../../components/plan/PlanDetail';

const PlanLandingPage = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isDetailVisible, setIsDetailVisible] = useState<boolean>(false);
  const [planId, setPlanId] = useState<string>();
  const plans = useQuery<PlanType[]>('plans', () => getPlans());
  const onPlanClick = (planId: string) => {
    setPlanId(planId);
    setIsDetailVisible(true);
  };
  let content;
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <a onClick={() => onPlanClick(record.id)}>{text}</a>
      ),
    },
    {
      title: 'Number of Steps',
      dataIndex: 'stepCount',
      key: 'stepCount',
    },
    {
      title: 'Create Date',
      dataIndex: 'createDate',
      key: 'createDate',
      render: renderDateWithTime,
    },
  ];

  if (plans && plans.data) {
    content = (
      <Table
        dataSource={plans.data}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
    );
  } else {
    content = <div>Loading...</div>;
  }

  const handleAddPlan = () => {
    setIsModalVisible(true);
  };
  const onDone = () => {
    setIsModalVisible(false);
  };
  const onCloseDetail = () => {
    setIsDetailVisible(false);
  };

  return (
    <>
      <Card
        title={
          <>
            <div className="page-title">
              <h1>Plans</h1>
              <div>
                <Button type="primary" onClick={handleAddPlan}>
                  + Add Plan
                </Button>
              </div>
            </div>
          </>
        }
      >
        {content}
      </Card>
      <Modal
        title="New Plan"
        visible={isModalVisible}
        destroyOnClose={true}
        footer={null}
        width={800}
      >
        <PlanNew onDone={onDone} />
      </Modal>
      {!!planId && isDetailVisible && (
        <PlanDetail planId={planId} onClose={onCloseDetail} />
      )}
    </>
  );
};

export default PlanLandingPage;
