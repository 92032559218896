// these should match feature table and FeatureNames.ts
export enum StudyFeatures {
  FORUM = 'FORUM',
  FORMS = 'FORMS',
  ACTIVITIES = 'ACTIVITIES',
  PEER_REFERRALS = 'PEER_REFERRALS',
  MEDTRACKER = 'MEDTRACKER',
  BADGES = 'BADGES',
  QNA = 'QNA',
  FOLLOW_PARTICIPANT = 'FOLLOW_PARTICIPANT',
  TEST_KITS = 'TEST_KITS',
  INCENTIVES = 'INCENTIVES',
  ACCESS_CODE_ENROLLMENT = 'ACCESS_CODE_ENROLLMENT',
  FORUM_POST_PUBLISH_DATE_MUTABLE = 'FORUM_POST_PUBLISH_DATE_MUTABLE',
  AVATAR_CUSTOM = 'AVATAR_CUSTOM',
  GOAL_SETTING = 'GOAL_SETTING',
  SURVEYS = 'SURVEYS',
  EXIT_INTERVIEW = 'EXIT_INTERVIEW',
  APPOINTMENTS = 'APPOINTMENTS',
  SHOW_DOB = 'SHOW_DOB',
  SHOW_GENDER_IDENTITY = 'SHOW_GENDER_IDENTITY',
  RESOURCE = 'RESOURCE',
}
