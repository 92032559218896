import { Button } from 'antd';
import React from 'react';

interface ForumAddBulkButtonsProp {
  current: number;
  stepsLength: number;
  onPrev: () => void;
  onNext: () => void;
  onSave?: () => void;
}

const ForumAddBulkButtons = (props: ForumAddBulkButtonsProp) => {
  return (
    <div className="steps-action">
      {props.current < props.stepsLength - 1 && (
        <Button type="primary" onClick={() => props.onNext()}>
          Next
        </Button>
      )}
      {props.current === props.stepsLength - 1 && (
        <Button
          type="primary"
          onClick={() => (props.onSave ? props.onSave() : null)}
        >
          Save
        </Button>
      )}
      {props.current > 0 && (
        <Button style={{ margin: '0 8px' }} onClick={() => props.onPrev()}>
          Previous
        </Button>
      )}
    </div>
  );
};

export default ForumAddBulkButtons;
