import { Button, FormInstance } from 'antd';
import React from 'react';
import '../../pages/study/plan/planNew.scss';

interface NewPlanStepButtonsProp {
  current: number;
  form: FormInstance<any> | undefined;
  onPrev: () => void;
  onNext: () => void;
  onCancel: () => void;
}

const NewPlanStepButtons = (props: NewPlanStepButtonsProp) => {
  const stepCount = 2;

  const onNext = async () => {
    if (props.form) {
      try {
        await props.form.validateFields();
        const errors = props.form.getFieldsError();
        if (errors.length > 0) {
          props.form.submit();
          props.onNext();
        }
      } catch (err) {
        console.log('Validation error found.');
      }
    }
  };

  return (
    <div className="steps-action">
      <Button style={{ margin: '0 8px' }} onClick={() => props.onCancel()}>
        Cancel
      </Button>
      {props.current > 0 && (
        <Button style={{ margin: '0 8px' }} onClick={() => props.onPrev()}>
          Previous
        </Button>
      )}
      {props.current < stepCount - 1 && (
        <Button type="primary" onClick={onNext} htmlType="submit">
          Next
        </Button>
      )}
      {props.current === stepCount - 1 && (
        <Button htmlType="submit" type="primary">
          Done
        </Button>
      )}
    </div>
  );
};

export default NewPlanStepButtons;
