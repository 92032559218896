export const validateRequiredMessage = {
  required: '${label} is required!',
};

export const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 12 },
  },
};
