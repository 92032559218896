import { Button, Col, Input, message, Popconfirm, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ExpertProfileType } from 'types/serverTypes/qnaTypes';
import {
  deleteExpertProfile,
  updateExpertProfile,
} from '../../service/configService';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';

interface ExpertProfileEditProp {
  onClose: () => void;
  profile: ExpertProfileType;
}
const ExpertProfileEdit = (props: ExpertProfileEditProp) => {
  const [updatedProfile, setUpdatedProfile] = useState<ExpertProfileType>(
    props.profile
  );
  const onDescriptionChange = (e) => {
    const { value } = e.target;
    setUpdatedProfile({
      ...updatedProfile,
      profileDescription: value,
    });
  };

  const queryClient = useQueryClient();
  const profileMutation = useMutation(updateExpertProfile, {
    onError: (error, variables, context) => {
      message.error('Error in updating expert profile.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('expertProfiles');
      message.success('Expert profile successfully updated.');
    },
  });
  const profileDeleteMutation = useMutation(deleteExpertProfile, {
    onError: (error, variables, context) => {
      message.error('Error in deleting expert profile.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('expertProfiles');
      message.success('Expert profile successfully deleted.');
    },
  });
  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error('Image must be smaller than 1MB!');
    }

    if (isJpgOrPng && isLt1M) {
      getBase64(file, (imageUrl: any) => {
        setUpdatedProfile({
          ...updatedProfile,
          profileUri: imageUrl,
        });
      });
    }
    return false; // do not upload the image. it will be saved as base64 string.
  };

  const onSave = () => {
    profileMutation.mutate(updatedProfile);
    props.onClose();
  };

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onDeletePhoto = () => {
    setUpdatedProfile({
      ...updatedProfile,
      profileUri: '',
    });
  };

  const confirmDelete = (e) => {
    //delete profile
    if (props.profile.id) {
      profileDeleteMutation.mutate(props.profile.id);
    }
    props.onClose();
  };

  if (!props.profile) {
    return <Spin />;
  }
  return (
    <div>
      {updatedProfile.id > 0 && (
        <Row className="marginBottom">
          <Col span={3}>Id:</Col>
          <Col span={21}>{updatedProfile.id}</Col>
        </Row>
      )}
      <Row className="marginBottom">
        <Col span={3}>Description:</Col>
        <Col span={21}>
          <Input.TextArea
            rows={8}
            value={updatedProfile.profileDescription}
            onChange={onDescriptionChange}
          />
        </Col>
      </Row>
      <Row className="marginBottom">
        <Col span={3}>Photo:</Col>
        <Col span={21}>
          {updatedProfile.profileUri && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <img
                  src={updatedProfile.profileUri}
                  width={100}
                  height={100}
                  style={{
                    borderWidth: 1,
                    borderColor: 'black',
                    borderStyle: 'solid',
                  }}
                />
              </div>
              <div>
                <Button type="link" onClick={onDeletePhoto}>
                  Delete
                </Button>
              </div>
            </div>
          )}
          {!updatedProfile.profileUri && (
            <ImgCrop rotate shape="round">
              <Upload listType="picture-card" beforeUpload={beforeUpload}>
                + Upload
              </Upload>
            </ImgCrop>
          )}
        </Col>
      </Row>
      <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
        {!!props.profile.id && (
          <div style={{ marginRight: 10 }}>
            <Popconfirm
              title="Are you sure to delete this profile?"
              onConfirm={confirmDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          </div>
        )}
        <div style={{ marginRight: 10 }}>
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
        </div>
        <div>
          <Button onClick={props.onClose}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};

export default ExpertProfileEdit;
