import { Button, Col, Input, message, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { updateEmailTemplate } from '../../service/configService';
import { EmailTemplateType } from '../../types/serverTypes/emailTypes';

interface EmailTemplateEditProp {
  onClose: () => void;
  template: EmailTemplateType;
}
const EmailTemplateEdit = (props: EmailTemplateEditProp) => {
  const [updatedTemplate, setUpdatedTemplate] = useState<EmailTemplateType>(
    props.template
  );
  const onSubjectChange = (e) => {
    const { value } = e.target;
    setUpdatedTemplate({
      ...updatedTemplate,
      subject: value,
    });
  };
  const onBodyChange = (e) => {
    const { value } = e.target;
    setUpdatedTemplate({
      ...updatedTemplate,
      body: value,
    });
  };
  const queryClient = useQueryClient();
  const templateMutation = useMutation(updateEmailTemplate, {
    onError: (error, variables, context) => {
      message.error('Error in updating email template.');
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('emailTemplates');
      message.success('Email template successfully updated.');
    },
  });

  const onSave = () => {
    // save the template
    templateMutation.mutate(updatedTemplate);
    props.onClose();
  };
  if (!props.template) {
    return <Spin />;
  }
  return (
    <div>
      <Row className="marginBottom">
        <Col span={3}>Id:</Col>
        <Col span={21}>{updatedTemplate.id}</Col>
      </Row>
      <Row className="marginBottom">
        <Col span={3}>Type:</Col>
        <Col span={21}>{updatedTemplate.type}</Col>
      </Row>
      <Row className="marginBottom">
        <Col span={3}>Subject:</Col>
        <Col span={21}>
          <Input value={updatedTemplate.subject} onChange={onSubjectChange} />
        </Col>
      </Row>
      <Row className="marginBottom">
        <Col span={3}>Body:</Col>
        <Col span={21}>
          <Input.TextArea
            rows={8}
            value={updatedTemplate.body}
            onChange={onBodyChange}
          />
        </Col>
      </Row>
      <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
        <div style={{ marginRight: 10 }}>
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
        </div>
        <div>
          <Button onClick={props.onClose}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateEdit;
