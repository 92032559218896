import React from 'react';
import { Form, Input } from 'antd';
import NewPlanStepButtons from './NewPlanStepButtons';
import { NewPlanType } from 'types/serverTypes/planTypes';
import './Plan.scss';
import {
  formItemLayout,
  validateRequiredMessage,
} from '../../constant/UIConstants';

interface NewPlanEntryProp {
  current: number;
  onPrev: () => void;
  onNext: () => void;
  onCancel: () => void;
  newPlan: NewPlanType;
}

const NewPlanEntry = (props: NewPlanEntryProp) => {
  const [form] = Form.useForm();

  return (
    <Form
      {...formItemLayout}
      validateMessages={validateRequiredMessage}
      form={form}
      name="newPlanEntryForm"
      initialValues={{
        title: props.newPlan?.title,
        numberOfSteps: props.newPlan.numberOfSteps,
      }}
    >
      <div className="form-content">
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="numberOfSteps"
          label="Number of Steps"
          rules={[{ required: true }]}
        >
          <Input type="number" />
        </Form.Item>
      </div>
      <NewPlanStepButtons
        current={props.current}
        onPrev={props.onPrev}
        onNext={props.onNext}
        onCancel={props.onCancel}
        form={form}
      />
    </Form>
  );
};

export default NewPlanEntry;
