import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';
import { _ } from 'lodash';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import axios from '../api';
import { entitySchema } from '../schema';
import {
  ActivityArguments,
  ActivityComponentArguments,
  ActivitySummaryArguments,
  DeleteActivityArguments,
  deleteActivityAsync,
  getActivityAsync,
  getActivityCategoriesAsync,
  getActivitySummariesAsync,
  getActivityTypesAsync,
  publishActivityAsync,
  unpublishActivityAsync,
  updateActivityComponentAsync,
  upsertActivityAsync,
} from './activities.types';
import { ActivityType } from '../../types/serverTypes/activityTypes';
import { NormalizerResult } from '../../types';

const getActivitySummaries = (args: ActivitySummaryArguments) => {
  return axios({
    method: 'get',
    url: `/a/activity/all/summaries?${_.map(
      _.keys(args),
      (key: string) => `${key}=${args[key]}`
    ).join('&')}`,
  });
};

const getActivity = (args: ActivityArguments) => {
  const { id } = args;
  return axios({
    method: 'get',
    url: `/a/activity/${id}`,
  });
};

// const getCategories = () => {
//   return axios({
//     method: 'get',
//     url: `/a/activity/list/categories`
//   });
// };

const getTypes = () => {
  return axios({
    method: 'get',
    url: `/a/activity/list/types`,
  });
};

const upsertActivity = (activity: ActivityType) => {
  return axios({
    method: 'post',
    url: `/a/activity/`,
    data: {
      activity,
    },
  });
};

const deleteActivity = (args: DeleteActivityArguments) => {
  return axios({
    method: 'put',
    url: `/a/activity/${args.id}/delete`,
    data: args,
  });
};

const updateActivityComponent = (args: ActivityComponentArguments) => {
  return axios({
    method: 'put',
    url: `/a/activity/updateComponent/${args.type}/${args.typeId}`,
    data: {
      component: args.component,
    },
  });
};

const publishActivity = (id: number) => {
  return axios({
    method: 'put',
    url: `/a/activity/${id}/publish`,
  });
};

const unpublishActivity = (id: number) => {
  return axios({
    method: 'put',
    url: `/a/activity/${id}/unpublish`,
  });
};

const uploadActivityImage = (image: File, id: number) => {
  const formData = new FormData();
  formData.append('file', image);
  return axios({
    method: 'POST',
    url: `/a/activity/${id}/upload/`,
    data: formData,
  });
};

function* refreshActivitySummariesHandler(args: ActivitySummaryArguments) {
  yield put(getActivitySummariesAsync.request(args));
}

function* refreshActivityHandler(args: ActivityArguments) {
  yield put(getActivityAsync.request(args));
}

function* getActivitySummariesHandler(
  action: ReturnType<typeof getActivitySummariesAsync.request>
): Generator {
  try {
    const args: ActivitySummaryArguments = action.payload;
    const response: AxiosResponse = (yield call(
      getActivitySummaries,
      args
    )) as AxiosResponse;
    const { entities } = normalize(
      response.data,
      entitySchema.activities
    ) as NormalizerResult;
    const { activities } = entities;

    yield put(getActivitySummariesAsync.success(activities));
  } catch (error) {
    yield put(getActivitySummariesAsync.failure(error));
  }
}

function* getActivityHandler(
  action: ReturnType<typeof getActivityAsync.request>
): Generator {
  try {
    const args: ActivityArguments = action.payload;
    const response: AxiosResponse = (yield call(
      getActivity,
      args
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.activities
    ) as NormalizerResult;
    const { activities } = entities;

    yield put(getActivityAsync.success(activities));
  } catch (error) {
    yield put(getActivityAsync.failure(error));
  }
}

// function* getCategoriesHandler(
//   action: ReturnType<typeof getActivityCategoriesAsync.request>
// ): Generator {
//   try {
//     const response: AxiosResponse = (yield call(
//       getCategories
//     )) as AxiosResponse;
//     const { entities } = normalize(
//       response.data,
//       entitySchema.activityCategories
//     ) as NormalizerResult;
//     const { activityCategories } = entities;

//     yield put(getActivityCategoriesAsync.success(activityCategories));
//   } catch (error) {
//     yield put(getActivityCategoriesAsync.failure(error));
//   }
// }

function* getTypesHandler(
  action: ReturnType<typeof getActivityTypesAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(getTypes)) as AxiosResponse;
    const { entities } = normalize(
      response.data,
      entitySchema.activityTypes
    ) as NormalizerResult;
    const { activityTypes } = entities;

    yield put(getActivityTypesAsync.success(activityTypes));
  } catch (error) {
    yield put(getActivityTypesAsync.failure(error));
  }
}

function* upsertActivityHandler(
  action: ReturnType<typeof upsertActivityAsync.request>
): Generator {
  try {
    const activity = action.payload;
    const response: AxiosResponse = (yield call(
      upsertActivity,
      activity
    )) as AxiosResponse;
    // If there is an activity background image, we need to upload the image and update
    // the activity.
    if (response.data.id && activity.upload && activity.background) {
      const { upload: image } = activity;
      const uploadResponse: AxiosResponse = (yield call(
        uploadActivityImage,
        image,
        response.data.id
      )) as AxiosResponse;
      activity.background.filename = uploadResponse.data.link;
      const updateResponse: AxiosResponse = (yield call(
        updateActivityComponent,
        {
          type: 'activity',
          typeId: response.data.id,
          component: activity,
        }
      )) as AxiosResponse;
      response.data.background.filename = uploadResponse.data.link;
    }
    const { entities } = normalize(
      [response.data],
      entitySchema.activities
    ) as NormalizerResult;
    const { activities } = entities;

    yield put(upsertActivityAsync.success(activities));

    const args: ActivitySummaryArguments = {
      pageNumber: 0,
      pageSize: 100000,
      includeUnpublished: true,
      includeDeleted: true,
    };
    yield call(refreshActivitySummariesHandler, args);
  } catch (error) {
    yield put(upsertActivityAsync.failure(error));
  }
}

function* updateActivityComponentHandler(
  action: ReturnType<typeof updateActivityComponentAsync.request>
): Generator {
  try {
    const args = action.payload;
    const response: AxiosResponse = (yield call(
      updateActivityComponent,
      args
    )) as AxiosResponse;

    yield put(updateActivityComponentAsync.success());
  } catch (error) {
    yield put(updateActivityComponentAsync.failure(error));
  }
}

function* deleteActivityHandler(
  action: ReturnType<typeof deleteActivityAsync.request>
): Generator {
  try {
    const response: AxiosResponse = (yield call(
      deleteActivity,
      action.payload
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.activities
    ) as NormalizerResult;
    const { activities } = entities;

    const args: ActivitySummaryArguments = {
      pageNumber: 0,
      pageSize: 100000,
      includeUnpublished: true,
      includeDeleted: true,
    };
    yield call(refreshActivitySummariesHandler, args);

    yield put(deleteActivityAsync.success(activities));
  } catch (error) {
    yield put(deleteActivityAsync.failure(error));
  }
}

function* publishActivityHandler(
  action: ReturnType<typeof publishActivityAsync.request>
): Generator {
  try {
    const id: number = action.payload;
    const response: AxiosResponse = (yield call(
      publishActivity,
      id
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.activities
    ) as NormalizerResult;
    const { activities } = entities;

    yield put(publishActivityAsync.success(activities));
  } catch (error) {
    yield put(publishActivityAsync.failure(error));
  }
}

function* unpublishActivityHandler(
  action: ReturnType<typeof unpublishActivityAsync.request>
): Generator {
  try {
    const id: number = action.payload;
    const response: AxiosResponse = (yield call(
      unpublishActivity,
      id
    )) as AxiosResponse;
    const { entities } = normalize(
      [response.data],
      entitySchema.activities
    ) as NormalizerResult;
    const { activities } = entities;

    yield put(unpublishActivityAsync.success(activities));
  } catch (error) {
    yield put(unpublishActivityAsync.failure(error));
  }
}

function* getActivitySummariesWatcher() {
  yield takeLatest(
    getType(getActivitySummariesAsync.request),
    getActivitySummariesHandler
  );
}

function* getActivityWatcher() {
  yield takeLatest(getType(getActivityAsync.request), getActivityHandler);
}

// function* getCategoriesWatcher() {
//   yield takeLatest(
//     getType(getActivityCategoriesAsync.request),
//     getCategoriesHandler
//   );
// }

function* getTypesWatcher() {
  yield takeLatest(getType(getActivityTypesAsync.request), getTypesHandler);
}

function* upsertActivityWatcher() {
  yield takeLatest(getType(upsertActivityAsync.request), upsertActivityHandler);
}

function* updateActivityComponentWatcher() {
  yield takeLatest(
    getType(updateActivityComponentAsync.request),
    updateActivityComponentHandler
  );
}

function* deleteActivityWatcher() {
  yield takeLatest(getType(deleteActivityAsync.request), deleteActivityHandler);
}

function* publishActivityWatcher() {
  yield takeLatest(
    getType(publishActivityAsync.request),
    publishActivityHandler
  );
}

function* unpublishActivityWatcher() {
  yield takeLatest(
    getType(unpublishActivityAsync.request),
    unpublishActivityHandler
  );
}

export default function* formsSaga() {
  yield all([
    fork(getActivitySummariesWatcher),
    fork(getActivityWatcher),
    // fork(getCategoriesWatcher),
    fork(getTypesWatcher),
    fork(upsertActivityWatcher),
    fork(updateActivityComponentWatcher),
    fork(deleteActivityWatcher),
    fork(publishActivityWatcher),
    fork(unpublishActivityWatcher),
  ]);
}
