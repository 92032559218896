import { Modal, Spin, Table } from 'antd';
import EmailTemplateEdit from '../../../components/config/EmailTemplateEdit';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getEmailTemplates } from '../../../service/configService';
import { EmailTemplateType } from '../../../types/serverTypes/emailTypes';

const EmailTemplateConfig = () => {
  const [isDetailVisible, setIsDetailVisible] = useState<boolean>(false);
  const [template, setTemplate] = useState<EmailTemplateType>();

  const emailTemplates = useQuery<EmailTemplateType[]>(
    'emailTemplates',
    getEmailTemplates
  );

  const onTemplateClick = (template: EmailTemplateType) => {
    setTemplate(template);
    setIsDetailVisible(true);
  };
  const onCloseDetail = () => {
    setIsDetailVisible(false);
  };

  if (emailTemplates.isLoading) {
    return <Spin />;
  }
  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <a onClick={() => onTemplateClick(record)}>{text}</a>
      ),
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Body',
      dataIndex: 'body',
      key: 'body',
    },
  ];
  return (
    <div>
      <Table dataSource={emailTemplates.data} columns={columns} rowKey="id" />
      <Modal
        title="Edit Template"
        visible={isDetailVisible}
        destroyOnClose={true}
        footer={null}
        width={800}
      >
        {template && (
          <EmailTemplateEdit template={template} onClose={onCloseDetail} />
        )}
      </Modal>
    </div>
  );
};

export default EmailTemplateConfig;
