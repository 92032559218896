import { getStudyId } from '../studyService';
import axios from '../../redux/api';
import { matchPath } from 'react-router-dom';
import { ParticipantType } from '../../types/serverTypes/studyTypes';
import { NoteType } from '../../types/serverTypes/noteTypes';

export const getParticipants = async () => {
  const studyId = getStudyId();
  const response = await axios({
    method: 'get',
    url: `/a/participant/participantsByStudy/${studyId}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

export const getFlaggedParticipants = async () => {
  const response = await axios({
    method: 'get',
    url: `/a/social/flaggedParticipants`,
  });
  return response.data;
};

export const getDefaultTab = (pathname) => {
  const match = matchPath<{ participantsSubpage: string }>(pathname, {
    path: '/study/:studyId/participants/:participantsSubpage',
  });

  if (match && match.params && match.params.participantsSubpage) {
    return match.params.participantsSubpage;
  }
  return 'participants';
};

export const getParticipant = async (participantId) => {
  if (participantId > 0) {
    const response = await axios({
      method: 'get',
      url: `/a/participant/${participantId}`,
    });
    return response.data;
  }
  return null;
};

export const saveParticipant = async (participant: ParticipantType) => {
  await axios({
    method: 'put',
    url: '/a/participant',
    headers: {
      'Content-Type': 'application/json',
    },
    data: participant,
  });
};

export const getParticipantNotes = async (participantId: number) => {
  const response = await axios({
    method: 'get',
    url: `/a/note/${participantId}`,
  });
  return response.data;
};

export const saveParticipantNote = async (note: NoteType) => {
  await await axios({
    method: 'put',
    url: `/a/note/${note.participantId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: note,
  });
};

export const getRequestedParticipantTab = () => {
  const match = matchPath<{ participantSubpage: string }>(
    window.location.pathname,
    {
      path: '/study/:studyId/participants/:participantId/:participantSubpage',
    }
  );

  if (match && match.params && match.params.participantSubpage) {
    return match.params.participantSubpage;
  }

  return '';
};
