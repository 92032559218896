import { matchPath } from 'react-router-dom';
import { StudyFeatureType } from '../../../server/src/types/studyTypes';
import axios from '../redux/api';
import { QueryClient } from 'react-query';

export const getStudyFeatures = async (_studyId?: number) => {
  const match = matchPath<{ studyId: string }>(window.location.pathname, {
    path: '/study/:studyId/',
  });

  let studyIdParam;
  if (_studyId) {
    studyIdParam = _studyId;
  } else if (match && match.params && match.params.studyId) {
    studyIdParam = match.params.studyId;
  }
  const response = await axios.get(`/a/study/${studyIdParam}/features`);
  return response.data;
};

export const getStudyArms = async () => {
  const match = matchPath<{ studyId: string }>(window.location.pathname, {
    path: '/study/:studyId',
  });

  if (match && match.params && match.params.studyId) {
    const response = await axios.get(
      `/a/study/${match.params.studyId}/studyArms`
    );
    return response.data;
  }
  return null;
};

export const updateStudyFeature = async (featureData: StudyFeatureType) => {
  const response = await axios.put(`/a/study/feature`, featureData);
  return response.data;
};

export const getStudyId = (currentPath?: string) => {
  const pathname = currentPath ?? window.location.pathname;
  // get study based on the url
  const match = matchPath<{ studyId: string }>(pathname, {
    path: '/study/:studyId',
  });

  if (match && match.params && match.params.studyId) {
    if (!isNaN(parseInt(match.params.studyId, 10))) {
      return parseInt(match.params.studyId, 10);
    }
  }
  return -1;
};

export const getDashboardData = async (studyId: number = 1) => {
  const response = await axios({
    method: 'get',
    url: `/a/dashboard/${studyId}`,
  });
  return response.data;
};

export const hasFeature = async (
  featureName,
  studyId?: number,
  studyArmId?: number
) => {
  const queryClient = new QueryClient();
  const features: StudyFeatureType[] = await queryClient.fetchQuery(
    ['studyFeatures', studyId],
    () => getStudyFeatures(studyId)
  );
  const filteredFeature = features.filter(
    (feature) =>
      feature.feature === featureName &&
      feature.enabled &&
      ((studyArmId && feature.studyArmIds.indexOf(studyArmId) > -1) ||
        !studyArmId)
  );
  return filteredFeature && filteredFeature?.length > 0;
};
