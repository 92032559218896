import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Select, Spin, Upload } from 'antd';
import React, { useState } from 'react';
import {
  ForumCommentType,
  ForumUploadPostType,
} from '../../types/serverTypes/forumTypes';
import base64js from 'base64-js';
import { ForumUploadPostTypes } from '../../../../server/src/constants/forumConstants';
import ForumAddBulkButtons from './ForumAddBulkButtons';
import { getStudyFeatures } from '../../service/studyService';
import { useQuery } from 'react-query';
import { StudyFeatures } from '../../constant/serverConstants/featureConstants';
import { StudyFeatureType } from '../../types/serverTypes/studyTypes';

const Excel = require('exceljs');

interface ExcelSummaryData {
  totalRows: number;
  numPosts: number;
  numComments: number;
  numImagePosts: number;
  numYoutubePosts: number;
  numLinkPosts: number;
}
interface ForumAddBulkUploadProp {
  onUploaded: (workbook) => void;
  onStudyArmChange: (id) => void;
  onPrev: () => void;
  onNext: () => void;
  current: number;
  stepsLength: number;
  studyArmId?: number;
}

const ForumAddBulkUpload = (props: ForumAddBulkUploadProp) => {
  const [excelSummaryData, setExcelSummaryData] = useState<ExcelSummaryData>();
  const [forumPosts, setForumPosts] = useState<ForumUploadPostType[]>([]);
  const features = useQuery<StudyFeatureType[]>('studyFeatures', () =>
    getStudyFeatures()
  );

  const [fileList, setFileList] = useState<any[]>([]);
  const beforeUpload = (file) => {
    setFileList([file]);

    handleImport(file);

    return false;
  };

  const getImageData = (workbook, worksheet) => {
    let imageData = {};
    for (const image of worksheet.getImages()) {
      const img = workbook.model.media.find(
        (m, index) => index.toString() == image.imageId
      );
      if (img) {
        const imageBase64 =
          'data:image/png;base64,' + base64js.fromByteArray(img.buffer as any);
        imageData[image.range.tl.nativeRow] = imageBase64;
      }
    }

    return imageData;
  };
  const getForumPostType = (val) => {
    if (val === 'comment') return ForumUploadPostTypes.Comment;
    if (val === 'child_comment') return ForumUploadPostTypes.ChildComment;
    return ForumUploadPostTypes.Post;
  };

  const handleImport = (file) => {
    const wb = new Excel.Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const buffer = reader.result;

      let hasLoadedFirstSheet = false;
      let forumPostsTmp: ForumUploadPostType[] = [];

      wb.xlsx.load(buffer).then((workbook) => {
        workbook.eachSheet((sheet, id) => {
          if (!hasLoadedFirstSheet) {
            const imageData = getImageData(workbook, sheet);
            let excelSummary: ExcelSummaryData = {
              totalRows: 0,
              numPosts: 0,
              numComments: 0,
              numImagePosts: 0,
              numYoutubePosts: 0,
              numLinkPosts: 0,
            };
            sheet.eachRow((row, rowIndex) => {
              if (rowIndex > 1) {
                const type = getForumPostType(row.values[1]);
                const title = row.values[2];
                const body = row.values[3];
                const username = row.values[4];
                const link = row.values[5] ? row.values[5].text : '';
                const image = imageData[rowIndex - 1] ?? '';
                const youtubeUrl = row.values[7] ? row.values[7].text : '';
                const createDate = row.values[8];
                excelSummary = {
                  ...excelSummary,
                  totalRows: excelSummary.totalRows + 1,
                  numPosts:
                    type === ForumUploadPostTypes.Post
                      ? excelSummary.numPosts + 1
                      : excelSummary.numPosts,
                  numComments:
                    type === ForumUploadPostTypes.Comment ||
                    type === ForumUploadPostTypes.ChildComment
                      ? excelSummary.numComments + 1
                      : excelSummary.numComments,
                  numLinkPosts: link
                    ? excelSummary.numLinkPosts + 1
                    : excelSummary.numLinkPosts,
                  numImagePosts: image
                    ? excelSummary.numImagePosts + 1
                    : excelSummary.numImagePosts,
                  numYoutubePosts: youtubeUrl
                    ? excelSummary.numYoutubePosts + 1
                    : excelSummary.numYoutubePosts,
                };

                if (
                  type === ForumUploadPostTypes.Comment ||
                  type === ForumUploadPostTypes.ChildComment
                ) {
                  let commentData: ForumCommentType = {
                    body,
                    username,
                    childComments: [],
                    createDate,
                  };
                  if (type === ForumUploadPostTypes.Comment) {
                    if (forumPostsTmp.length > 0) {
                      forumPostsTmp[forumPostsTmp.length - 1].comments.push(
                        commentData
                      );
                    }
                  } else if (type === ForumUploadPostTypes.ChildComment) {
                    const commentIndex =
                      forumPostsTmp[forumPostsTmp.length - 1].comments.length -
                      1;
                    if (commentIndex > -1) {
                      forumPostsTmp[forumPostsTmp.length - 1].comments[
                        commentIndex
                      ].childComments.push(commentData);
                    }
                  }
                } else {
                  let forumPost: ForumUploadPostType = {
                    type: ForumUploadPostTypes.Post,
                    id: rowIndex,
                    title,
                    body,
                    username,
                    link,
                    youtubeUrl: youtubeUrl,
                    image,
                    createDate,
                    comments: [],
                  };
                  forumPostsTmp.push(forumPost);
                }
              }
              hasLoadedFirstSheet = true;
            });
            setExcelSummaryData(excelSummary);
          }
        });
        setForumPosts(forumPostsTmp);
      });
    };
  };

  if (features.isLoading) {
    return <Spin />;
  }
  const handleStudyArmIdChange = (value) => {
    props.onStudyArmChange(value);
  };
  const onNext = () => {
    if (props.studyArmId) {
      if (fileList && fileList.length > 0) {
        props.onUploaded(forumPosts);
        props.onNext();
      } else {
        message.error('Please upload an excel file to load');
      }
    } else {
      message.error('Please select study arm ID');
    }
  };

  const getStudyArmIds = () => {
    const feature = features.data?.find(
      (f) => f.feature === StudyFeatures.FORUM
    );
    if (feature) {
      return feature.studyArmIds;
    }
    return [];
  };
  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <div>Choose study arm for the forum posts: </div>
        <Select
          onChange={handleStudyArmIdChange}
          placeholder="Choose a study arm id"
        >
          {getStudyArmIds().map((arm) => {
            return (
              <Select.Option value={arm} key={arm}>
                {arm}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div>
        <Upload accept="xlsx" beforeUpload={beforeUpload} fileList={fileList}>
          <Button icon={<UploadOutlined />}>
            Click to Upload Forum Posts File (xlsx)
          </Button>
        </Upload>
      </div>
      {excelSummaryData && (
        <div style={{ marginTop: 20 }}>
          <Row>
            <Col span={4}>Number of records</Col>
            <Col span={1}>{excelSummaryData?.totalRows}</Col>
            <Col span={4}>Number of link posts</Col>
            <Col span={1}>{excelSummaryData?.numLinkPosts}</Col>
          </Row>
          <Row>
            <Col span={4}>Number of posts</Col>
            <Col span={1}>{excelSummaryData?.numPosts}</Col>
            <Col span={4}>Number of image posts</Col>
            <Col span={1}>{excelSummaryData?.numImagePosts}</Col>
          </Row>
          <Row>
            <Col span={4}>Number of comments</Col>
            <Col span={1}>{excelSummaryData?.numComments}</Col>
            <Col span={4}>Number of youtube posts</Col>
            <Col span={1}>{excelSummaryData?.numYoutubePosts}</Col>
          </Row>
        </div>
      )}
      <ForumAddBulkButtons
        onPrev={props.onPrev}
        onNext={onNext}
        current={props.current}
        stepsLength={props.stepsLength}
      />
    </>
  );
};

export default ForumAddBulkUpload;
