export default class FeatureNames {
  //these should match the feature table and StudyDb
  static FORUM = 'FORUM';
  static FORMS = 'FORMS';
  static ACTIVITIES = 'ACTIVITIES';
  static PEER_REFERRALS = 'PEER_REFERRALS';
  static MEDTRACKER = 'MEDTRACKER';
  static BADGES = 'BADGES';
  static QNA = 'QNA';
  static FOLLOW_PARTICIPANT = 'FOLLOW_PARTICIPANT';
  static TEST_KITS = 'TEST_KITS';
  static INCENTIVES = 'INCENTIVES';
  static ACCESS_CODE_ENROLLMENT = 'ACCESS_CODE_ENROLLMENT';
  static FORUM_POST_PUBLISH_DATE_MUTABLE = 'FORUM_POST_PUBLISH_DATE_MUTABLE';
  static AVATAR_CUSTOM = 'AVATAR_CUSTOM';
  static GOAL_SETTING = 'GOAL_SETTING';
  static SURVEYS = 'SURVEYS';
  static EXIT_INTERVIEW = 'EXIT_INTERVIEW';
  static APPOINTMENTS = 'APPOINTMENTS';
  static SHOW_DOB = 'SHOW_DOB';
  static SHOW_GENDER_IDENTITY = 'SHOW_GENDER_IDENTITY';
  static RESOURCE = 'RESOURCE';
  static PLAN = 'PLAN';
}
