import React, { ReactNode, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { _ } from 'lodash';
import moment from 'moment';
import { Table, Card, Button, Modal, message, Switch, Spin } from 'antd';
import { renderDateWithTime } from '../../../components/util/Util';
import * as selectors from '../../../redux/selectors';
import IApplicationState from '../../../types/state.types';
import {
  getActivitySummariesAsync,
  ActivitySummaryArguments,
  getActivityTypesAsync,
  getActivityAsync,
  ActivityArguments,
  deleteActivityAsync,
  DeleteActivityArguments,
  unpublishActivityAsync,
  publishActivityAsync,
} from '../../../redux/activities/activities.types';
import ActivityFormContainer from '../../../components/activity/ActivityFormContainer';
import SearchInput from '../../../components/search/SearchInput';
import './activity.scss';
import {
  ActivityType,
  ActivityTypeType,
  FeedbackType,
  OptionType,
  QuestionType,
} from '../../../types/serverTypes/activityTypes';
import { ResourceTopicType } from '../../../types/serverTypes/resourceTypes';
import { TopicType } from '../../../../../server/src/types/forumTypes';
import { useQuery } from 'react-query';
import { getKnowledgeCenterTopics } from '../../../service/topicService';

const { Column } = Table;
const { confirm } = Modal;

interface StateProps {
  viewForm: boolean;
  currentId: number;
  copiedId: number;
  searchTerm: string;
  showDeleted: boolean;
}

interface DispatchProps {
  loadSummaries: typeof getActivitySummariesAsync.request;
  loadTypes: typeof getActivityTypesAsync.request;
  getActivity: typeof getActivityAsync.request;
  deleteActivity: typeof deleteActivityAsync.request;
  unpublishActivity: typeof unpublishActivityAsync.request;
  publishActivity: typeof publishActivityAsync.request;
}

interface ComponentProps extends StateProps, DispatchProps {
  activities: Optional<ActivityType[]>;
  topics: Optional<ResourceTopicType[]>;
  types: Optional<ActivityTypeType[]>;
}

const ActivitiesLandingPage = (props: ComponentProps) => {
  const [state, setState] = useState<StateProps>({
    viewForm: false,
    currentId: -1,
    copiedId: -1,
    searchTerm: '',
    showDeleted: false,
  });

  const topics = useQuery<TopicType[]>(
    'resourceTopics',
    getKnowledgeCenterTopics
  );

  useEffect(() => {
    const { loadSummaries, loadTypes } = props;
    const args: ActivitySummaryArguments = {
      pageNumber: 0,
      pageSize: 100000,
      includeUnpublished: true,
      includeDeleted: true,
    };
    loadSummaries(args);
    loadTypes();
  }, []);

  const closeForm = () => {
    setState({
      ...state,
      viewForm: false,
      currentId: -1,
      copiedId: -1,
    });
  };

  const openForm = () => {
    setState({
      ...state,
      viewForm: true,
      currentId: -1,
    });
  };

  const deleteActivity = (id: number) => {
    const { deleteActivity } = props;
    confirm({
      title: 'Are you sure you want to delete this activity?',
      okText: 'Delete',
      okType: 'danger',
      onOk() {
        deleteActivity({ id });
      },
      onCancel() {},
    });
  };

  const copyActivity = (id: number) => {
    const { getActivity } = props;
    getActivity({ id });
    setState({
      ...state,
      viewForm: true,
      copiedId: id,
    });
  };

  const copyDeepLink = (id: number) => {
    const deepLink = `hmp://activity/${id}`;
    navigator.clipboard.writeText(deepLink).then(
      () => {
        message.success('Copied to clipboard!');
      },
      () => {
        message.error('Failed to copy to clipboard.');
      }
    );
  };

  const unpublishActivity = (id: number) => {
    const { unpublishActivity } = props;
    confirm({
      title: 'Are you sure you want to unpublish this activity?',
      content:
        'When editing previously published activities, be sure not to add/modify the structure of the activity. Only edit existing questions, options, or feedbacks.',
      okText: 'Un-Publish',
      okType: 'primary',
      onOk() {
        unpublishActivity(id);
      },
      onCancel() {},
    });
  };

  const publishActivity = (id: number) => {
    const { publishActivity } = props;
    confirm({
      title: 'Are you sure you want to publish this activity?',
      content:
        'Once published, you will want to avoid adding new questions, options, and feedbacks to an activity.',
      okText: 'Publish',
      okType: 'primary',
      onOk() {
        publishActivity(id);
      },
      onCancel() {},
    });
  };

  // Renderers
  const rowClassName = (record: any, index: number): string => {
    return index % 2 === 0 ? 'tr-even-color' : 'tr-odd-color';
  };

  const expandedRowRenderer = (record: any): ReactNode => {
    const columns = [
      { title: 'Description', dataIndex: 'description', key: 'description' },
      {
        title: 'Completed Count',
        dataIndex: 'numberCompleted',
        key: 'numberCompleted',
      },
      {
        title: 'Average (%)',
        dataIndex: 'average',
        key: 'average',
        render: renderNull,
      },
    ];
    const data = [record];
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const renderActions = (activity: ActivityType) => {
    const { activities } = props;
    const currentActivity = activities?.find((a) => a.id === activity.id);
    const displayPublish = currentActivity
      ? currentActivity.publishDate === null
      : true;
    return (
      <div className="activity-action-container">
        <a
          title="View"
          className="activity-action"
          onClick={(e) => {
            e.stopPropagation();
            renderActivityViewEditModal(activity.id!);
          }}
        >
          <i className="far fa-eye fa-lg" />
        </a>
        <a
          title="Delete"
          className="activity-action"
          onClick={(e) => {
            e.stopPropagation();
            deleteActivity(activity.id!);
          }}
        >
          <i className="fal fa-trash-alt fa-lg" />
        </a>
        <a
          title="Copy"
          className="activity-action"
          onClick={(e) => {
            e.stopPropagation();
            copyActivity(activity.id!);
          }}
        >
          <i className="fal fa-copy fa-lg" />
        </a>
        <a
          title="Copy deep link to clipboard"
          className="activity-action"
          onClick={(e) => {
            e.stopPropagation();
            copyDeepLink(activity.id!);
          }}
        >
          <i className="far fa-link" />
        </a>
        {displayPublish ? (
          <a
            title="Publish"
            className="activity-action"
            onClick={(e) => {
              e.stopPropagation();
              publishActivity(activity.id!);
            }}
          >
            <i className="fal fa-calendar-plus fa-lg" />
          </a>
        ) : (
          <a
            title="Unpublish"
            className="activity-action"
            onClick={(e) => {
              e.stopPropagation();
              unpublishActivity(activity.id!);
            }}
          >
            <i className="fal fa-calendar-times fa-lg" />
          </a>
        )}
      </div>
    );
  };

  const renderActivityViewEditModal = (id: number) => {
    const { getActivity } = props;
    getActivity({ id });
    setState({
      ...state,
      viewForm: true,
      currentId: id,
    });
  };

  const renderBoolean = (value: boolean) => (value ? 'Yes' : 'No');

  const renderNull = (value: any) => value || '--';

  const renderType = (value: string) => {
    switch (value) {
      case 'quiz':
        return 'Quizzes';
      case 'category':
        return 'Break it down';
      case 'category no-answer':
        return 'Break it down (no answer)';
      case 'cyoa':
        return 'Call the shots';
      case 'fill in the blank':
        return 'Fill it in';
      case 'goals':
        return 'Goals';
      case 'ranking':
        return 'Sort it out';
      case 'screener':
        return 'Assessments';
    }
  };

  const capitalize = (s: string): string => {
    return s
      ? s
          .split(' ')
          .map((word) => word[0].toUpperCase() + word.substring(1))
          .join(' ')
      : s;
  };

  // Sorters
  const sorterAlphabetical = (a: ActivityType, b: ActivityType) =>
    a.title.localeCompare(b.title);

  const sorterNumerical = (a: ActivityType, b: ActivityType) => a.id! - b.id!;

  const sorterPublishDate = (a: ActivityType, b: ActivityType) => {
    if (!a.publishDate && !b.publishDate) {
      return 0;
    }
    if (!a.publishDate) {
      return -1;
    }
    if (!b.publishDate) {
      return 1;
    }
    return moment(a.publishDate).unix() - moment(b.publishDate).unix();
  };

  const sorterDeleteDate = (a: ActivityType, b: ActivityType) => {
    if (!a.deleteDate && !b.deleteDate) {
      return 0;
    }
    if (!a.deleteDate) {
      return -1;
    }
    if (!b.deleteDate) {
      return 1;
    }
    return moment(a.deleteDate).unix() - moment(b.deleteDate).unix();
  };

  const sorterLastUpdateDate = (a: ActivityType, b: ActivityType) => {
    return moment(a.lastUpdateDate).unix() - moment(b.lastUpdateDate).unix();
  };

  // Filters
  const onFilterType = (value: any, record: any) => {
    const { types } = props;
    const current = types?.find((t) => t.type === record.type);
    return current ? current.id === parseInt(value) : false;
  };

  const onFilterTopic = (value: any, record: any) => {
    return record.topicId === +value;
  };

  const onFilterPublished = (value: any, record: any) => {
    return value === 'yes' ? record.isPublished : !record.isPublished;
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, searchTerm: event.target.value });
  };

  const onShowDeletedChange = (checked: boolean) => {
    setState({ ...state, showDeleted: checked });
  };

  const handleRowClick = (record: ActivityType, index?: number) => {
    if (record && record.id) {
      renderActivityViewEditModal(record.id);
    }
  };

  const preprocessCopiedActivity = (activity: ActivityType): ActivityType => {
    // This method strips the id property off the activity and its child components.
    const processed: ActivityType = _.cloneDeep(activity);
    delete processed.id; // = undefined;
    processed.publishDate = undefined;
    if (processed.options) {
      processed.options = processed.options.map((option): OptionType => {
        if (option.feedback) {
          option.feedback = _.omit(option.feedback, 'id');
        }
        return _.omit(option, 'id');
      });
    }
    if (processed.feedback) {
      processed.feedback = processed.feedback.map((feedback): FeedbackType => {
        return _.omit(feedback, 'id');
      });
    }
    if (processed.questions) {
      processed.questions = processed.questions.map(
        (question): QuestionType => {
          if (question.options) {
            question.options = question.options.map((option): OptionType => {
              if (option.feedback) {
                option.feedback = _.omit(option.feedback, 'id');
              }
              return _.omit(option, 'id');
            });
          }
          if (question.feedback) {
            question.feedback = question.feedback.map(
              (feedback): FeedbackType => {
                return _.omit(feedback, 'id');
              }
            );
          }
          return _.omit(question, 'id');
        }
      );
    }
    return processed;
  };

  const renderTopic = (topicId: number, row, index) => {
    let topicMatched;
    topics.data?.find(
      (topic) =>
        (topicMatched = topic.children?.find(
          (topicChild) => topicChild.id === topicId
        ))
    );
    if (topicMatched) {
      return <div>{topicMatched.title}</div>;
    }
    return '';
  };

  const { activities, types } = props;
  const { viewForm, currentId, copiedId, searchTerm, showDeleted } = state;
  const typeFilters = types
    ? types.map((type) => {
        return {
          text: renderType(type.type),
          value: type.id.toString(),
        };
      })
    : [];
  const topicFilters = topics.data
    ? topics.data.reduce((filterTopics: any, topic) => {
        if (topic.children) {
          return filterTopics.concat(
            topic.children.map((topicChild) => {
              return {
                text: topicChild.title,
                value: (topicChild.id as any).toString(),
              };
            })
          );
        }
        return filterTopics;
      }, [])
    : [];
  let currentActivity = activities?.find((a) =>
    currentId > -1 ? a.id === currentId : a.id === copiedId
  );
  if (copiedId > -1 && currentActivity) {
    currentActivity = preprocessCopiedActivity(currentActivity);
  }
  let displayedActivities = activities;
  if (searchTerm && searchTerm.length) {
    const regex = new RegExp(searchTerm, 'i');
    displayedActivities = _.filter(
      displayedActivities,
      (a) =>
        regex.test(a.title) ||
        regex.test(a.description) ||
        a.questions?.some((q) => regex.test(q.text)) ||
        a.options?.some((o) => regex.test(o.text)) ||
        a.feedback?.some(
          (f) => regex.test(f.text) || (f.title ? regex.test(f.title) : false)
        )
    ) as ActivityType[];
  }
  if (!showDeleted) {
    displayedActivities = _.filter(displayedActivities, (a) => !a.deleteDate);
  }
  if (topics.isLoading) return <Spin />;

  return (
    <div className="activity-list">
      <Card
        title={
          <div className="activity-title">
            <h1>Activities</h1>
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                openForm();
              }}
            >
              + Add Activity
            </Button>
          </div>
        }
      >
        {viewForm && (
          <ActivityFormContainer
            activity={currentActivity}
            visible={viewForm}
            closeHandler={closeForm}
            creative={
              currentId === -1 || !!(currentActivity && !currentActivity.id)
            }
            copied={copiedId > -1}
          />
        )}
        <div className="table-filter-search">
          <SearchInput
            className="search-bar"
            id="search-input"
            onChangeHandler={handleSearchChange}
            placeholder="Search Activities"
            value={state.searchTerm}
          />
          <div className="filter-items">
            <span className="filter">
              Show Deleted
              <Switch checked={showDeleted} onChange={onShowDeletedChange} />
            </span>
          </div>
        </div>
        <Table
          dataSource={displayedActivities}
          rowKey="id"
          rowClassName={rowClassName}
          expandedRowRender={expandedRowRenderer}
          scroll={{ y: '65vh' }}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => handleRowClick(record), // click row
              onDoubleClick: (event) => {}, // double click row
              onContextMenu: (event) => {}, // right button click row
              onMouseEnter: (event) => {}, // mouse enter row
              onMouseLeave: (event) => {}, // mouse leave row
            };
          }}
        >
          <Column title="ID" dataIndex="id" key="id" sorter={sorterNumerical} />
          <Column title="Title" dataIndex="title" sorter={sorterAlphabetical} />
          <Column
            title="Topic"
            dataIndex="topicId"
            filters={topicFilters}
            onFilter={onFilterTopic}
            render={renderTopic}
          />
          <Column
            title="Type"
            dataIndex="type"
            render={renderType}
            filters={typeFilters}
            onFilter={onFilterType}
          />
          <Column
            title="Date Modified"
            dataIndex="lastUpdateDate"
            defaultSortOrder="descend"
            render={renderDateWithTime}
            sorter={sorterLastUpdateDate}
          />
          <Column
            title="Publish Date"
            dataIndex="publishDate"
            render={renderDateWithTime}
            sorter={sorterPublishDate}
          />
          {state.showDeleted ? (
            <Column
              title="Delete Date"
              dataIndex="deleteDate"
              render={renderDateWithTime}
              sorter={sorterDeleteDate}
            />
          ) : undefined}
          <Column title="Actions" render={renderActions} />
        </Table>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    activities: selectors.getActivities(state),
    types: selectors.getTypes(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadSummaries: (args: ActivitySummaryArguments) =>
      dispatch(getActivitySummariesAsync.request(args)),
    loadTypes: () => dispatch(getActivityTypesAsync.request()),
    getActivity: (args: ActivityArguments) =>
      dispatch(getActivityAsync.request(args)),
    deleteActivity: (args: DeleteActivityArguments) =>
      dispatch(deleteActivityAsync.request(args)),
    unpublishActivity: (id: number) =>
      dispatch(unpublishActivityAsync.request(id)),
    publishActivity: (id: number) => dispatch(publishActivityAsync.request(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesLandingPage);
