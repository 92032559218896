import { Empty, Spin, Table } from 'antd';
import { renderDateWithTime } from '../../../components/util/Util';
import React from 'react';
import { useQuery } from 'react-query';
import { getAppointments } from '../../../service/appointmentService';
import { Link } from 'react-router-dom';

interface ParticipantAppointmentsProp {
  participantId: number;
}
const ParticipantAppointments = (props: ParticipantAppointmentsProp) => {
  const participantAppointments = useQuery('participantAppointments', () =>
    getAppointments(props.participantId)
  );

  if (participantAppointments.isLoading) {
    return <Spin />;
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => {
        return (
          <Link to={`../appointments/appointments/edit/${record.id}`}>
            {text}
          </Link>
        );
      },
    },
    {
      title: 'Appointment Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: renderDateWithTime,
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Admin Notes',
      dataIndex: 'adminNotes',
      key: 'adminNotes',
    },
    {
      title: 'Meeting Method',
      dataIndex: 'meetingMethod',
      key: 'meetingMethod',
    },
    {
      title: 'Is Confirmed?',
      dataIndex: 'isConfirmed',
      key: 'isConfirmed',
      render: (text, record) => {
        if (record.isConfirmed) {
          return 'Yes';
        }
        return 'No';
      },
    },
  ];

  return (
    <div>
      {participantAppointments.data.length > 0 && (
        <Table
          dataSource={participantAppointments.data}
          columns={columns}
          rowKey="id"
        />
      )}
      {participantAppointments.data.length === 0 && (
        <Empty description="No Appointment Found" />
      )}
    </div>
  );
};

export default ParticipantAppointments;
