import React, { ReactNode } from 'react';
import { Form, Input, Select } from 'antd';
import NewPlanStepButtons from './NewPlanStepButtons';
import { NewPlanType } from '../../types/serverTypes/planTypes';
import {
  formItemLayout,
  validateRequiredMessage,
} from '../../constant/UIConstants';
import Title from 'antd/lib/typography/Title';
import TextArea from 'antd/lib/input/TextArea';
import { PlanStepDataTypes } from '../../constant/serverConstants/planConstants';
import { convertCamelCaseToLabel } from '../../service/util';

interface NewPlanStepEntryProp {
  current: number;
  onPrev: () => void;
  onNext: () => void;
  onCancel: () => void;
  newPlan: NewPlanType;
}

const NewPlanStepEntry = (props: NewPlanStepEntryProp) => {
  const [form] = Form.useForm();
  const getEntryFields = () => {
    let inputFields: ReactNode[] = [];
    for (let index = 0; index < (props.newPlan.numberOfSteps || 0); index++) {
      inputFields.push(
        <div key={index}>
          <Title level={5} style={{ marginLeft: 30 }}>
            Step {index + 1}
          </Title>
          <Form.Item
            name={`step_${index + 1}_title`}
            label="Title"
            rules={[{ required: true }]}
            key={`step_${index + 1}_title`}
          >
            <Input maxLength={200} />
          </Form.Item>
          <Form.Item
            name={`step_${index + 1}_description`}
            label="Description"
            key={`step_${index + 1}_description`}
          >
            <TextArea maxLength={500} rows={2} />
          </Form.Item>
          <Form.Item
            name={`step_${index + 1}_maxItemCount`}
            label="Max Item Count"
            rules={[{ required: true }]}
            key={`step_${index + 1}_maxItemCount`}
          >
            <Input type="number" max={50} min={1} />
          </Form.Item>
          <Form.Item
            name={`step_${index + 1}_dataType`}
            label="Data Type"
            rules={[{ required: true }]}
            key={`step_${index + 1}_dataType`}
          >
            <Select placeholder="What type of data does this step contain?">
              {Object.keys(PlanStepDataTypes).map((key) => (
                <Select.Option
                  aria-selected="true"
                  key={PlanStepDataTypes[key]}
                  value={PlanStepDataTypes[key]}
                >
                  {convertCamelCaseToLabel(
                    Object.keys(PlanStepDataTypes)[
                      Object.values(PlanStepDataTypes).indexOf(
                        PlanStepDataTypes[key]
                      )
                    ]
                  )}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      );
    }
    return inputFields;
  };
  return (
    <Form
      form={form}
      {...formItemLayout}
      name="newPlanStepEntryForm"
      validateMessages={validateRequiredMessage}
    >
      <div className="form-content">{getEntryFields()}</div>
      <NewPlanStepButtons
        current={props.current}
        onPrev={props.onPrev}
        onNext={props.onNext}
        onCancel={props.onCancel}
        form={form}
      />
    </Form>
  );
};

export default NewPlanStepEntry;
