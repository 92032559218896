import axios from '../redux/api';

export const getKnowledgeCenterTopics = async () => {
  const response = await axios({
    method: 'get',
    url: '/a/topic/knowledgecenter/1/topicStructure',
  });

  return response.data;
};

export const getForumCenterTopics = async (studyArm: number) => {
  const response = await axios({
    method: 'get',
    url: `/a/topic/forum/${studyArm}/topicStructure`,
  });

  return response.data;
};
