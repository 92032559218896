import { Col, message, Row, Select, Table } from 'antd';
import { renderDateWithTime } from '../../components/util/Util';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  ForumUploadPostType,
  TopicType,
} from '../../../../server/src/types/forumTypes';
import { getForumCenterTopics } from '../../service/topicService';
import ForumAddBulkButtons from './ForumAddBulkButtons';
import { createForumPostsInBulk } from '../../service/forumService';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getStudyId } from '../../service/studyService';

interface ForumAddBulkDetailProp {
  forumUploadPosts: ForumUploadPostType[];
  onPrev: () => void;
  onNext: () => void;
  current: number;
  stepsLength: number;
  studyArmId: number;
}

const ForumAddBulkDetail = (
  props: ForumAddBulkDetailProp & RouteComponentProps
) => {
  const [forumPosts, setForumPosts] = useState<ForumUploadPostType[]>(
    props.forumUploadPosts
  );
  const [isRowSelected, setIsRowSelected] = useState<boolean>(false);

  const topics = useQuery<TopicType[]>('forumTopics', () =>
    getForumCenterTopics(props.studyArmId)
  );
  const onTopicChange = (rowIndex, value) => {
    const updatedForumPosts = forumPosts.map((post, index) => {
      if (index === rowIndex) {
        return {
          ...post,
          topicId: value,
        };
      } else {
        return post;
      }
    });
    setForumPosts(updatedForumPosts);
  };
  const validate = () => {
    return forumPosts.findIndex((f) => !f.topicId) === -1;
  };

  const onSave = async () => {
    if (validate()) {
      await createForumPostsInBulk(forumPosts, props.studyArmId);
      message.success('Forum posts created successfully');
      // move to the forum screen
      const studyId = getStudyId();
      props.history.push(`/study/${studyId}/forums`);
    } else {
      message.error('Please select topic for each record');
    }
  };

  const onTopicChangeBulk = (value) => {
    const updatedForumPosts = forumPosts.map((post) => {
      if (post.isSelected) {
        return {
          ...post,
          topicId: value,
        };
      } else {
        return post;
      }
    });
    setForumPosts(updatedForumPosts);
  };
  const createDate = new Date();
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Body',
      dataIndex: 'body',
      key: 'body',
      width: 400,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Topic',
      key: 'body',
      render: (text, record, index) => {
        return (
          <Select
            showSearch
            placeholder="Select a topic"
            optionFilterProp="children"
            value={record.topicId}
            onChange={(v) => onTopicChange(index, v)}
            filterOption={(input, option) =>
              (option?.children as any)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {topics.data?.map((topic) => {
              return (
                <Select.Option value={topic.id} key={topic.id}>
                  {topic.title}
                </Select.Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (text, record) => {
        return record.image ? (
          <img src={record.image} width={50} height={50} />
        ) : null;
      },
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
    },
    {
      title: 'Youtube',
      dataIndex: 'youtubeUrl',
      key: 'link',
    },
    {
      title: 'Create Date',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (date) => {
        return date ? renderDateWithTime(date) : renderDateWithTime(createDate);
      },
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: ForumUploadPostType[]
    ) => {
      const updatedForumPosts = forumPosts.map((post) => {
        return {
          ...post,
          isSelected: selectedRowKeys.includes(post.id),
        };
      });
      setIsRowSelected(selectedRowKeys.length > 0);
      setForumPosts(updatedForumPosts);
    },
  };

  const renderComment = (record: ForumUploadPostType) => {
    return record.comments.map((comment, index) => {
      return (
        <div key={`comment-${index}`} style={{ marginBottom: 10 }}>
          <Row>
            <Col span={2} offset={2}>
              Comment {index + 1}:
            </Col>
            <Col>{comment.body}</Col>
          </Row>
          <Row>
            <Col span={2} offset={2}>
              Username:
            </Col>
            <Col>{comment.username}</Col>
          </Row>
          {comment.childComments.map((childComment, cindex) => {
            return (
              <div key={`childcomment-${cindex}`}>
                <Row>
                  <Col span={2} offset={4}>
                    Child Comment:
                  </Col>
                  <Col>{childComment.body}</Col>
                </Row>
                <Row>
                  <Col span={2} offset={4}>
                    Username:
                  </Col>
                  <Col>{childComment.username}</Col>
                </Row>
              </div>
            );
          })}
        </div>
      );
    });
  };
  return (
    <>
      <Row>
        <Col span={3} style={{ marginBottom: 20 }}>
          Update topic in bulk:
        </Col>
        <Col>
          <Select
            disabled={!isRowSelected}
            showSearch
            placeholder="Select a topic"
            optionFilterProp="children"
            onChange={(v) => onTopicChangeBulk(v)}
            filterOption={(input, option) =>
              (option?.children as any)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {topics.data?.map((topic) => {
              return (
                <Select.Option value={topic.id} key={topic.id}>
                  {topic.title}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <Table
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        dataSource={forumPosts}
        columns={columns}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => renderComment(record),
          rowExpandable: (record) => record.comments.length > 0,
        }}
      />
      <ForumAddBulkButtons
        onPrev={props.onPrev}
        onNext={props.onNext}
        current={props.current}
        stepsLength={props.stepsLength}
        onSave={onSave}
      />
    </>
  );
};

export default withRouter(ForumAddBulkDetail);
