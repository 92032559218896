import { Button, Input, Spin, Table } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as _ from 'lodash';
import React, { Component } from 'react';
import { tsXLXS } from 'ts-xlsx-export';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import axios from '../../../redux/api';
import * as selectors from '../../../redux/selectors';
import IApplicationState from '../../../types/state.types';
import { AdminUserType } from '../../../types/serverTypes/adminTypes';
import { stringifyJSON } from '../../../service/util';

interface StateProps {
  user: Optional<AdminUserType>;
  hasDataAnalystRole: Optional<boolean>;
}
const initialState = {
  query: '' as string,
  data: undefined as any,
  previewLength: 10,
  waiting: false as boolean,
  error: '' as string,
};

type ComponentState = Readonly<typeof initialState>;

interface ComponentProps extends ComponentState, StateProps {}

class AdHocQueryLandingPage extends Component<ComponentProps, {}> {
  readonly state: ComponentState = initialState;

  getData = async () => {
    const { query, waiting } = this.state;
    if (waiting) {
      return;
    }
    this.setState({ waiting: true, data: undefined, error: '' });
    let response;

    try {
      response = await axios({
        timeout: -1, // never time out
        method: 'post',
        url: '/a/adHocQuery/',
        data: { query },
      });
      this.setState({ data: response.data, waiting: false });
    } catch (err: any) {
      console.log(JSON.stringify(err));
      this.setState({ waiting: false, error: `${err.message}\n${err.stack}` });
    }
  };

  enterHandler = async (evt: any) => {
    if (evt.code === 'Enter' && evt.ctrlKey) {
      this.getData();
    }
  };

  render() {
    const { hasDataAnalystRole } = this.props;
    const { data, previewLength, query, waiting, error } = this.state;
    const columns: any[] = [];
    const headers: any[] = [];

    if (!hasDataAnalystRole) {
      return <div>You do not have the proper role to see this page!</div>;
    }

    if (data) {
      if (data.rows.length) {
        _.keys(data.rows[0]).forEach((key: string) => {
          headers.push({ label: key, key });

          columns.push({
            title: key,
            dataIndex: key,
            render: (item) => {
              return stringifyJSON(item);
            },
          });
        });
      }
    }

    function exportXSLX() {
      tsXLXS().exportAsExcelFile(data.rows).saveAsExcelFile('newFile');
    }

    const fivePxMargin = { marginTop: '5px' };

    return (
      <div>
        Enter your query below and hit enter
        <TextArea
          style={{ marginTop: '10px', marginBottom: '10px' }}
          rows={4}
          value={query}
          onPressEnter={this.enterHandler}
          onChange={(e) => {
            this.setState({ query: e.target.value });
          }}
        />
        <Button disabled={waiting} onClick={this.getData}>
          Run Query
        </Button>{' '}
        <span>Press Ctrl-ENTER to run query</span>
        {waiting && (
          <div style={fivePxMargin}>
            <Spin size="large" style={{ marginLeft: '10px' }} />
          </div>
        )}
        {error && error.match(/Request failed with status code 408/) ? (
          <div style={fivePxMargin}>
            <p>This query timed out. Please rewrite it</p>
          </div>
        ) : (
          <div style={fivePxMargin}>
            <pre>{error}</pre>
          </div>
        )}
        {data?.rows && _.isArray(data.rows) ? (
          <div>
            <br />
            <Button onClick={exportXSLX}>Export XSLX</Button>
            <br /> <br />
            Here is a preview of{' '}
            <Input
              style={{ width: '75px' }}
              value={previewLength}
              onChange={(e) => {
                this.setState({ previewLength: e.target.value });
              }}
            />{' '}
            rows
            <br /> <br />
            <Table
              columns={columns}
              dataSource={
                _.slice(data.rows, 0, previewLength) as readonly object[]
              }
              pagination={false}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<IApplicationState, StateProps>(
  {
    user: selectors.getUser,
    hasDataAnalystRole: selectors.hasDataAnalystRole,
  }
);

export default connect(mapStateToProps, null)(AdHocQueryLandingPage);
