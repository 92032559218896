import {
  Button,
  Card,
  Col,
  Empty,
  message,
  Popconfirm,
  Row,
  Spin,
  Tooltip,
} from 'antd';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { resetParticipantAvatars } from '../../service/avatar/avatarService';
import {
  AvatarCategorySequenceType,
  AvatarPartCategoryType,
  AvatarPartType,
} from '../../types/serverTypes/avatarTypes';
import './AvatarCustom.scss';
import AvatarCustomPartEdit from './AvatarCustomPartEdit';
import AvatarCustomCategoryEdit from './AvatarCustomCategoryEdit';
import AvatarNewCategoryModal from './AvatarNewCategoryModal';
import AvatarNewPartModal from './AvatarNewPartModal';
import AvatarCustomCategory from './AvatarCustomCategory';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { arrayMoveImmutable } from 'array-move';
import {
  useGetAvatarCategories,
  useGetAvatarParts,
  useMoveAvatarPartMutation,
  useUpdateAvatarCategoryMutation,
  useUpdateAvatarCategorySequenceMutation,
  useUpdateAvatarPartMutation,
} from './queries';

interface AvatarCustomState {
  showNewCategoryModal: boolean;
  showNewPartModal: boolean;
  canDeleteCategory: boolean;
}
const AvatarCustom = () => {
  const [state, setState] = useState<AvatarCustomState>({
    showNewCategoryModal: false,
    showNewPartModal: false,
    canDeleteCategory: false,
  });

  const categories = useGetAvatarCategories();
  const [baseBody, setBaseBody] = useState<Map<number, AvatarPartType>>(
    new Map<number, AvatarPartType>()
  );
  const [displayParts, setDisplayParts] = useState<string[]>([]);

  const queryClient = useQueryClient();
  const parts = useGetAvatarParts();
  const [selectedPart, setSelectedPart] = useState<AvatarPartType>();
  const [selectedCategory, setSelectedCategory] =
    useState<AvatarPartCategoryType>();

  const onAvatarPartDetail = (part) => {
    setSelectedPart(part);
    setSelectedCategory(undefined);
    setState({ ...state, canDeleteCategory: false });

    if (part?.avatarPartCategoryId) {
      const categoryId = part?.avatarPartCategoryId;
      let updatedBaseBody = new Map(baseBody);
      updatedBaseBody.set(categoryId, part);
      resetDisplayParts(updatedBaseBody);
      setBaseBody(updatedBaseBody);
    }
  };

  const resetDisplayParts = (updatedBaseBody: Map<number, AvatarPartType>) => {
    let tmpDisplayParts: string[] = [];

    categories.data?.map((category) => {
      let tmpPart = updatedBaseBody.get(category.id);
      if (!!tmpPart) {
        tmpDisplayParts.push(tmpPart.svg);
      }
      updatedBaseBody.forEach((b) => {
        if (b.secondaryPartCategoryId == category.id) {
          if (b.secondarySvg) {
            tmpDisplayParts.push(b.secondarySvg);
          }
        }
      });
    });
    setDisplayParts(tmpDisplayParts);
  };

  const onCategoryDetail = (category, canDelete) => {
    setSelectedPart(undefined);
    setSelectedCategory(category);
    setState({ ...state, canDeleteCategory: canDelete });
  };

  const onUpdateAvatarPart = (data: any) => {
    setSelectedPart({ ...selectedPart, ...data });
  };

  const onUpdateCategory = (data: any) => {
    setSelectedCategory({ ...selectedCategory, ...data });
  };

  const onSaveAvatarPart = () => {
    if (selectedPart) {
      avatarPartMutation.mutate(selectedPart);
    }
  };
  const avatarPartMutation = useUpdateAvatarPartMutation(queryClient);

  const onSaveAvatarCategory = () => {
    if (selectedCategory) {
      avatarCategoryMutation.mutate(selectedCategory);
    }
  };
  const avatarCategoryMutation = useUpdateAvatarCategoryMutation(queryClient);

  const onClearSelection = (categoryIds: number[]) => {
    let updatedBaseBody = new Map(baseBody);
    categoryIds.forEach((categoryId) => updatedBaseBody.delete(categoryId));
    setBaseBody(updatedBaseBody);

    resetDisplayParts(updatedBaseBody);
  };

  const onAddCategory = () => {
    setState({
      ...state,
      showNewCategoryModal: true,
    });
  };
  const onCloseAddCategory = () => {
    setState({
      ...state,
      showNewCategoryModal: false,
    });
  };
  const onAddPart = () => {
    setState({
      ...state,
      showNewPartModal: true,
    });
  };
  const onCloseAddPart = () => {
    setState({
      ...state,
      showNewPartModal: false,
    });
  };
  const onCategoryDelete = () => {
    setSelectedCategory(undefined);
    setState({ ...state, canDeleteCategory: false });
  };
  const avatarPartMoveMutation = useMoveAvatarPartMutation(queryClient);

  const categoryMoveMutation =
    useUpdateAvatarCategorySequenceMutation(queryClient);

  const onDragEnd = async (result: any) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }

    // check if dropped on same location
    if (destination.droppableId === source.droppableId && type === 'part') {
      return;
    }

    if (type === 'category') {
      // reorder category
      const categoryId = parseInt(draggableId.replace('category-', ''));
      if (categories.data) {
        const reorderedCategories = arrayMoveImmutable(
          categories.data,
          source.index,
          destination.index
        );
        const updatedCategories: AvatarCategorySequenceType[] =
          reorderedCategories.map((category, index) => {
            return {
              categoryId: category.id,
              sequence: index + 1,
            };
          });
        // call api to update database'
        categoryMoveMutation.mutate(updatedCategories);
      }
    } else if (type === 'part') {
      // reorder the data
      const partId = draggableId.replace('part-', '');
      const partDragged = parts.data?.find((p) => p.id === parseInt(partId));
      if (partDragged) {
        let tmpPartDragged = {
          ...partDragged,
          avatarPartCategoryId: destination.droppableId,
        };
        onClearSelection([
          parseInt(destination.droppableId),
          parseInt(source.droppableId),
        ]);
        avatarPartMoveMutation.mutate(tmpPartDragged);
      }
    }
  };
  const onResetParticipantAvatars = async () => {
    try {
      let partsToApply: string[] = [];
      if (parts.data) {
        // find base set and default applied svg and secondary svg in order
        categories.data?.map((category) => {
          const partSvgsToApply: string[] = [];
          parts.data.forEach((part) => {
            if (part.isBaseSet && part.isBaseSetDefaultApplied) {
              if (part.avatarPartCategoryId === category.id) {
                partSvgsToApply.push(part.svg);
              }
              if (part.secondaryPartCategoryId === category.id) {
                if (part.secondarySvg) {
                  partSvgsToApply.push(part.secondarySvg);
                }
              }
            }
          });
          if (!!partSvgsToApply) {
            partsToApply.push(...partSvgsToApply);
          }
        });
      }

      await resetParticipantAvatars(partsToApply);
      message.success('Participants avatar reset successfully');
    } catch (err) {
      message.error(
        'Error while resetting participant avatar to baseline. ' + err
      );
    }
  };

  return (
    <>
      <Card
        title={
          <div className="avatar-title">
            <h1></h1>
            <div>
              <Button type="primary" onClick={onAddCategory}>
                + Add Category
              </Button>
              <Button
                type="primary"
                onClick={onAddPart}
                style={{ marginLeft: 10 }}
              >
                + Add Part
              </Button>
              <Popconfirm
                title={
                  <div>
                    This will set the baseline avatar used by new participants
                    created in future. Click Yes to continue.
                  </div>
                }
                onConfirm={onResetParticipantAvatars}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ marginLeft: 10 }}>Set Baseline Avatar</Button>
              </Popconfirm>
            </div>
          </div>
        }
      >
        <Row>
          <Col span={8}>
            <Tooltip
              title="Order of category determines how the parts are placed on avatar."
              placement="top"
            >
              <div className="section-label-container">Categories</div>
            </Tooltip>
            <Spin spinning={categories.isLoading}>
              {!categories.isLoading && categories.data?.length === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              {categories.data && parts.data && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="mainDroppable"
                    type="category"
                    direction="vertical"
                  >
                    {(provided) => (
                      <div
                        className="categories-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {categories.data.map((category, index) => (
                          <AvatarCustomCategory
                            key={category.id}
                            category={category}
                            parts={parts.data}
                            index={index}
                            baseBody={baseBody}
                            onAvatarPartDetail={onAvatarPartDetail}
                            onClearSelection={onClearSelection}
                            onCategoryDetail={onCategoryDetail}
                            selectedPart={selectedPart}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </Spin>
          </Col>
          <Col span={16}>
            <div className="detail-containers">
              <div className="section-label-container">Detail</div>
              {!selectedPart && !selectedCategory && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              {selectedPart && (
                <AvatarCustomPartEdit
                  selectedPart={selectedPart}
                  onUpdateAvatarPart={onUpdateAvatarPart}
                  onSaveAvatarPart={onSaveAvatarPart}
                  baseBody={displayParts}
                />
              )}
              {selectedCategory && (
                <AvatarCustomCategoryEdit
                  selectedCategory={selectedCategory}
                  onUpdateCategory={onUpdateCategory}
                  onSaveAvatarCategory={onSaveAvatarCategory}
                  canDelete={state.canDeleteCategory}
                  onDelete={onCategoryDelete}
                />
              )}
            </div>
          </Col>
        </Row>
      </Card>
      <AvatarNewCategoryModal
        showModal={state.showNewCategoryModal}
        onCancel={onCloseAddCategory}
      />
      <AvatarNewPartModal
        showModal={state.showNewPartModal}
        onCancel={onCloseAddPart}
      />
    </>
  );
};

export default AvatarCustom;
