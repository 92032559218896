"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.negate = negate;
exports.printIt = printIt;
exports.first = first;
exports.tail = tail;

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var curry = exports.curry = function curry(fn) {
  return function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return fn.length > args.length ? curry(fn.bind.apply(fn, [null].concat(args))) : fn.apply(undefined, args);
  };
};

var assoc = exports.assoc = curry(function (key, val, obj) {
  return _extends({}, obj, _defineProperty({}, key, val));
});

var assocPath = exports.assocPath = curry(function (keyPath, val, obj) {
  if (keyPath.length === 0) {
    return val;
  }

  if (keyPath.length === 1) {
    return assoc(keyPath[0], val, obj);
  } else {
    return _extends({}, obj, _defineProperty({}, keyPath[0], assocPath(keyPath.slice(1), val, obj[keyPath[0]])));
  }
});

// concatenate two arrays
// if set1 = [2, 4, 5] and set2 = [6, 0] then concat(set1, set2) = [2, 4, 5, 6, 0]
var concat = exports.concat = curry(function (set1, set2) {
  set1 = set1 || [];
  set2 = set2 || [];
  var idx = 0;
  var result = [];

  while (idx < set1.length) {
    result[result.length] = set1[idx++];
  }
  idx = 0;
  while (idx < set2.length) {
    result[result.length] = set2[idx++];
  }

  return result;
});

/**
 * given an object and a property name, returns the value of that property in the object
 * super complicated :)
 * but get really useful as a curried function
 */
var prop = exports.prop = curry(function (prop, obj) {
  return obj[prop];
});

/**
 * given a property name and a value, check if the property in the object equals the given value
 * e.g. propEq('id', 1, {id: 1, name: 'mukesh'}) === true
 * e.g. propEq('id', 2, {id: 1, name: 'mukesh'}) === false
 */
var propEq = exports.propEq = curry(function (prop, val, obj) {
  return equals(val, obj[prop]);
});

/**
 * Tells whether the two objects have same value for a property
 * E.g. p1 = {fname: 'rustom', lname: 'yadav'}; p2 = { fname: 'kuldev', lname: 'yadav'}
 * then eqProps('lname', p1, p2) === true
 * but eqProps('fname', p1, p2) === false
 */
var eqProps = exports.eqProps = curry(function (prop, o1, o2) {
  return equals(o1[prop], o2[prop]);
});

/**
 * In a given list, update the value at a particular index
 * E.g. update(10, 2, [0,1,2,3,4]) === [0,1,10,3,4]
 */
var update = exports.update = curry(function (index, val, arr) {
  if (index >= 0 && index < arr.length) {
    return [].concat(_toConsumableArray(arr.slice(0, index)), [val], _toConsumableArray(arr.slice(index + 1)));
  } else {
    return arr;
  }
});

/**
 * find a value inside a list/array and replace with a given value
 */
var findAndUpdate = exports.findAndUpdate = curry(function (findFn, val, arr) {
  var index = arr.findIndex(findFn);
  if (index >= 0) {
    return update(index, val, arr);
  } else {
    return arr;
  }
});

// prepend an element to an array
// e.g. prepend(4, [0, 1]) = [4, 0, 1]
var prepend = exports.prepend = curry(function (el, arr) {
  return concat([el], arr);
});

// append an element to end of an array
// e.g. append(4, [0, 1]) = [0, 1, 4]
var append = exports.append = curry(function (el, arr) {
  return concat(arr, [el]);
});

var pipe = exports.pipe = function pipe() {
  for (var _len2 = arguments.length, fns = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    fns[_key2] = arguments[_key2];
  }

  return function () {
    return fns.reduce(function (acc, fn) {
      return [fn.apply(null, acc)];
    }, arguments)[0];
  };
};

function negate(fn, context) {
  return function () {
    return !fn.apply(context, arguments);
  };
}

var dedupe = exports.dedupe = curry(function (arr, fn) {
  return arr.filter(function (item, index, self) {
    return index === self.findIndex(fn.bind(null, item));
  });
});

var equals = exports.equals = curry(function (a, b) {
  return a === b;
});

var eqBy = exports.eqBy = curry(function (fn, a, b) {
  return equals(fn.call(null, a), fn.call(null, b));
});

var find = exports.find = curry(function (pred, arr) {
  return arr.find(pred);
});

function printIt(item) {
  console.log("to print", item);
  return item;
}

var without = exports.without = curry(function (discardArr, arr) {
  return arr.filter(function (item) {
    return !discardArr.some(equals(item));
  });
});

var startsWith = exports.startsWith = curry(function (prefix, str) {
  if (!str) {
    return false;
  } else {
    return str.indexOf(prefix) === 0;
  }
});

function first(list) {
  return list[0];
}

var head = exports.head = first;

function tail(list) {
  return list.slice(1);
}

var any = exports.any = curry(function (pred, list) {
  if (list && list.some && typeof list.some === "function") {
    return list.some(pred);
  } else {
    return list.reduce(function (acc, item) {
      return acc || pred(item);
    }, false);
  }
});

var findIndex = exports.findIndex = curry(function (finder, list) {
  if (!list) {
    return -1;
  }

  var itemIndex = -1;

  for (var i = 0; i < list.length; i++) {
    if (finder(list[i])) {
      itemIndex = i;
      break;
    }
  }

  return itemIndex;
});

var R = {
  any: any,
  curry: curry,
  assoc: assoc,
  assocPath: assocPath,
  append: append,
  prepend: prepend,
  concat: concat,
  pipe: pipe,
  negate: negate,
  dedupe: dedupe,
  equals: equals,
  eqBy: eqBy,
  prop: prop,
  propEq: propEq,
  eqProps: eqProps,
  find: find,
  without: without,
  update: update,
  printIt: printIt,
  first: first,
  head: head,
  tail: tail,
  startsWith: startsWith,
  findIndex: findIndex
};

exports.default = R;
