import {
  NewPlanType,
  PlanStepEditItemType,
  PlanType,
} from '../types/serverTypes/planTypes';
import axios from '../redux/api';

export const getPlans = async () => {
  const response = await axios.get(`/a/plan/all`);
  return response.data;
};

export const addPlan = async (newPlan: NewPlanType) => {
  const response = await axios.post(`/a/plan`, newPlan);
  return response.data;
};

export const getPlanDetail = async (planId: string) => {
  const response = await axios.get(`/a/plan/detail/${planId}`);
  return response.data;
};
export const getParticipantPlans = async (
  participantId: number
): Promise<PlanType[]> => {
  const response = await axios.get(
    `/a/plan/participant/plans?participantId=${participantId}`
  );
  return response.data;
};
export const getParticipantPlanDetail = async (
  participantId: number,
  planId: string
) => {
  const response = await axios.get(
    `/a/plan/participant/planDetail?participantId=${participantId}&planId=${planId}`
  );
  return response.data;
};

export const updatePlanStepItems = async (
  participantId: number,
  stepItems: Map<string, PlanStepEditItemType[]>
) => {
  const stepItemArray = Array.from(stepItems, ([name, value]) => ({
    key: name,
    value: value,
  }));
  await axios.put(`/a/plan/participant/items`, {
    participantId: participantId,
    data: stepItemArray,
  });
};

export const deletePlan = async (planId: string) => {
  await axios({
    method: 'delete',
    url: `/a/plan/${planId}`,
  });
};
