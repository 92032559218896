import { Button, Form, Input, message, Spin, Typography } from 'antd';
import {
  formItemLayout,
  validateRequiredMessage,
} from '../../../constant/UIConstants';
import React, { useState } from 'react';
import {
  getParticipantPlanDetail,
  updatePlanStepItems,
} from '../../../service/planService';
import {
  PlanType,
  ParticipantPlanDetailType,
  ParticipantPlanStepDetailType,
  PlanStepEditItemType,
} from '../../../types/serverTypes/planTypes';
import '../Plan.scss';
import Title from 'antd/lib/typography/Title';
import { PlanStepDataTypes } from '../../../constant/serverConstants/planConstants';
import uuid from 'uuid';
import { parseJSON } from '../../../service/util';
import { DeleteOutlined } from '@ant-design/icons';
import ReactTelInput from 'react-international-telephone-input/lib/withStyles';
import { useQuery } from 'react-query';
import { getAppLookups } from '../../../service/lookupService';
const flagsImagePath = require('../../../assets/images/flags.png');
const { Text } = Typography;

interface ParticipantPlanDetailEditProp {
  plan: PlanType;
  participantId: number;
  onDone: () => void;
}

const ParticipantPlanDetailEdit = (props: ParticipantPlanDetailEditProp) => {
  const [form] = Form.useForm();
  const [editStepItems, setEditStepItems] = useState<
    Map<string, PlanStepEditItemType[]>
  >(new Map());
  const [participantPlanDetail, setParticipantPlanDetail] =
    useState<ParticipantPlanDetailType>();
  const appLookups = useQuery('applookups', () => getAppLookups());

  React.useEffect(() => {
    getParticipantPlanDetail(props.participantId, props.plan.id).then(
      (data) => {
        if (data) {
          setParticipantPlanDetail(data);
          let editStepItemsTmp: Map<string, PlanStepEditItemType[]> = new Map();
          data.steps.map((step) => {
            editStepItemsTmp.set(
              step.id,
              step.items.map((item) => {
                return { id: item.id, entry: item.entry };
              })
            );
          });
          setEditStepItems(editStepItemsTmp);
        }
      }
    );
  }, []);

  React.useEffect(() => form.resetFields(), [editStepItems]);
  const getStepItemName = (stepId, itemId, dataType, suffix?) => {
    return `step_${stepId}_item_${itemId}-${dataType}${
      suffix ? '-' + suffix : ''
    }`;
  };
  const onSubmit = async () => {
    try {
      const validResponse = await form.validateFields();
      const editStepItemsNew = getFormData(validResponse);
      await updatePlanStepItems(props.participantId, editStepItemsNew);
      message.success('Update successful!');
      props.onDone();
    } catch (ex) {
      message.error('Error occurred while trying to update');
    }
  };

  const getFormData = (formData) => {
    if (participantPlanDetail) {
      let editStepItemsTmp = new Map(editStepItems);
      for (let index = 0; index < participantPlanDetail.steps.length; index++) {
        const step = participantPlanDetail.steps[index];
        const items = editStepItemsTmp.get(step.id);
        if (items) {
          for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
            const item = items[itemIndex];
            if (step.dataType === PlanStepDataTypes.FreeForm) {
              const stepData =
                formData[getStepItemName(step.id, item.id, step.dataType)];
              if (stepData) {
                item.entry = {
                  data: stepData,
                };
              }
            } else if (step.dataType === PlanStepDataTypes.ContactInformation) {
              let stepData =
                formData[
                  getStepItemName(step.id, item.id, step.dataType, 'data')
                ];
              // handle case where phone number is not entered
              if (stepData === '+') {
                stepData = '';
              }
              const stepName =
                formData[
                  getStepItemName(step.id, item.id, step.dataType, 'name')
                ];
              if (stepData || stepName) {
                item.entry = {
                  data: stepData,
                  name: stepName,
                };
              } else {
                item.entry = undefined;
              }
            }
          }
        }
      }
      setEditStepItems(editStepItemsTmp);
      return editStepItemsTmp;
    }
    return editStepItems;
  };

  const getStepEdit = (step: ParticipantPlanStepDetailType) => {
    const items = editStepItems.get(step.id);
    // if no step item exists, show at least one
    if (!items || items.length === 0) {
      const newId = uuid();
      editStepItems.set(step.id, [{ id: newId, entry: {} }]);
      return getStepItemEdit(step, newId);
    } else {
      return items.map((item, index) => {
        return getStepItemEdit(step, item.id ?? index.toString());
      });
    }
  };
  const canAddMoreSteps = (
    step: ParticipantPlanStepDetailType,
    editStepItems_p: Map<string, PlanStepEditItemType[]>
  ) => {
    const items = editStepItems_p.get(step.id);
    const itemCount = items?.length || 0;
    return step.maxItemCount > itemCount;
  };

  const onAddNewStepItem = (step: ParticipantPlanStepDetailType) => {
    const editStepItemsNew = getFormData(form.getFieldsValue());

    const editStepItemsTmp = new Map(editStepItemsNew);
    const itemsTmp = editStepItemsTmp.get(step.id);

    if (itemsTmp) {
      itemsTmp.push({
        id: uuid(),
        entry: '',
      });
      editStepItemsTmp.set(step.id, itemsTmp);
    }
    setEditStepItems(editStepItemsTmp);
  };
  const getStepItemAddButton = (step: ParticipantPlanStepDetailType) => {
    if (canAddMoreSteps(step, editStepItems)) {
      return (
        <Button type="primary" onClick={() => onAddNewStepItem(step)}>
          Add
        </Button>
      );
    } else {
      return null;
    }
  };
  const onDelete = (stepId, itemId) => {
    const editStepItemsNew = getFormData(form.getFieldsValue());
    const editStepItemsTmp = new Map(editStepItemsNew);
    const stepItems = editStepItemsTmp.get(stepId);
    if (stepItems) {
      const updatedItems = stepItems.filter((items) => items.id !== itemId);
      editStepItemsTmp.set(stepId, updatedItems);
      setEditStepItems(editStepItemsTmp);
    }
  };

  const getStepItemEdit = (
    step: ParticipantPlanStepDetailType,
    itemId: string
  ) => {
    switch (step.dataType) {
      case PlanStepDataTypes.FreeForm:
        return (
          <div
            key={getStepItemName(step.id, itemId, step.dataType)}
            style={{ display: 'flex' }}
          >
            <div style={{ flex: 1 }}>
              <Form.Item
                label="Entry"
                name={getStepItemName(step.id, itemId, step.dataType)}
              >
                <Input />
              </Form.Item>
            </div>
            <div style={{ width: 50 }}>
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                onClick={() => onDelete(step.id, itemId)}
              />
            </div>
          </div>
        );
      case PlanStepDataTypes.ContactInformation:
        return (
          <div
            key={getStepItemName(step.id, itemId, step.dataType)}
            style={{ display: 'flex' }}
          >
            <div style={{ flex: 1 }}>
              <Form.Item
                label="Name"
                name={getStepItemName(step.id, itemId, step.dataType, 'name')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Contact Info"
                name={getStepItemName(step.id, itemId, step.dataType, 'data')}
              >
                <ReactTelInput
                  flagsImagePath={flagsImagePath}
                  defaultCountry={defaultCountryCode}
                />
              </Form.Item>
            </div>
            <div style={{ width: 50 }}>
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                danger
                onClick={() => onDelete(step.id, itemId)}
              />
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const getFieldInitialValue = (
    stepDetail: ParticipantPlanStepDetailType,
    entry: any,
    key,
    valueItem
  ) => {
    if (entry) {
      if (stepDetail.dataType === PlanStepDataTypes.FreeForm) {
        try {
          const obj = parseJSON(entry);
          return {
            [getStepItemName(key, valueItem.id, stepDetail.dataType)]:
              obj['data'],
          };
        } catch (err) {
          return {};
        }
      } else if (stepDetail.dataType === PlanStepDataTypes.ContactInformation) {
        try {
          const obj = parseJSON(entry);
          return {
            [getStepItemName(key, valueItem.id, stepDetail.dataType, 'name')]:
              obj['name'],
            [getStepItemName(key, valueItem.id, stepDetail.dataType, 'data')]:
              obj['data'],
          };
        } catch (err) {
          return {};
        }
      }
    }
    return {};
  };
  const getInitialValues = () => {
    let initialValues = {};
    for (let [key, stepItems] of editStepItems) {
      const stepDetail = participantPlanDetail?.steps.find(
        (step) => step.id === key
      );
      if (stepDetail) {
        stepItems.map((stepItem) => {
          initialValues = {
            ...initialValues,
            ...getFieldInitialValue(stepDetail, stepItem.entry, key, stepItem),
          };
        });
      }
    }
    return initialValues;
  };

  if (!participantPlanDetail || !editStepItems || appLookups.isLoading) {
    return <Spin />;
  }
  const defaultCountryCode =
    (appLookups.data ? appLookups.data['phone_default_country_code'] : 'us') ??
    'us';
  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        validateMessages={validateRequiredMessage}
        name="editPlanDetailForm"
        initialValues={getInitialValues()}
      >
        <div className="form-content">
          {participantPlanDetail.steps.map((step, index) => {
            return (
              <div key={step.id} style={{ marginBottom: 20 }}>
                <Title level={5}>
                  Step {index + 1}: {step.title}
                </Title>
                <Text style={{ marginBottom: 20 }}>{step.description}</Text>
                {getStepEdit(step)}
                {getStepItemAddButton(step)}
              </div>
            );
          })}
        </div>
        <div className="form-footer">
          <Button
            key="cancel"
            onClick={props.onDone}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button key="ok" onClick={onSubmit} type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ParticipantPlanDetailEdit;
