import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getType } from 'typesafe-actions';
import * as selectors from '../../../redux/selectors';
import {
  GetStringLookupArguments,
  getStringLookupsAsync,
  UpdateStringLookupArguments,
  updateStringLookupAsync,
} from '../../../redux/stringLookups/stringLookups.types';
import { StringLookupType } from '../../../types/serverTypes';
import { LookupKeywords } from '../../../constant/StringLookupConstant';
import IApplicationState from '../../../types/state.types';
import './stringLookup.scss';
import { Button, Card, Collapse, Empty, message } from 'antd';
import FroalaCustomUpload from '../../../components/activity/FroalaCustomUpload';
import { IApiRequestStatus } from '../../../types/api.types';
import { clearStatus } from '../../../redux/api/api.types';
import UnsavedLeavingGuard from '../../../components/util/UnsavedLeavingGuard';
import { RouteComponentProps, withRouter } from 'react-router';
const { Panel } = Collapse;

interface StateProps {
  stringLookups: Optional<StringLookupType[]>;
  getStringLookupStatus: IApiRequestStatus;
  getStringLookupsStatus: IApiRequestStatus;
  updateStringLookupStatus: IApiRequestStatus;
}

interface DispatchProps {
  loadStringLookups: typeof getStringLookupsAsync.request;
  updateStringLookup: typeof updateStringLookupAsync.request;
  clearStatus: typeof clearStatus;
}

interface ComponentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {}

interface ComponentState {
  isLoadingMap: Map<number, boolean>;
  stringLookups: Map<number, StringLookupType>;
  isUnsavedMap: Map<number, boolean>;
}

class StringLookupLandingPage extends Component<
  ComponentProps,
  ComponentState
> {
  constructor(props) {
    super(props);
    const { loadStringLookups } = this.props;
    loadStringLookups({
      types: [
        LookupKeywords.CARE_LOCATOR,
        LookupKeywords.CONSENT_FORM,
        LookupKeywords.COMMUNITY_GUIDELINES,
        LookupKeywords.ACCOUNT_DELETE_NOTICE,
      ],
    });
    this.state = {
      isLoadingMap: new Map(),
      stringLookups: new Map(),
      isUnsavedMap: new Map(),
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      getStringLookupsStatus,
      updateStringLookupStatus,
      clearStatus,
      stringLookups,
    } = this.props;
    if (
      !prevProps.getStringLookupsStatus.isSuccess &&
      getStringLookupsStatus.isSuccess
    ) {
      message.success('Successfully loaded string lookups');
      this.setState({
        isLoadingMap: new Map(stringLookups?.map((s) => [s.id, false])),
        stringLookups: new Map(stringLookups?.map((s) => [s.id, s])),
        isUnsavedMap: new Map(stringLookups?.map((s) => [s.id, false])),
      });
      clearStatus(getType(getStringLookupsAsync.success));
    }
    if (
      !prevProps.updateStringLookupStatus.isSuccess &&
      updateStringLookupStatus.isSuccess
    ) {
      message.success('Successfully updated string lookup');
      if (updateStringLookupStatus.params.id) {
        this.setState({
          isLoadingMap: new Map(
            this.state.isLoadingMap.set(
              updateStringLookupStatus.params.id,
              false
            )
          ),
          isUnsavedMap: new Map(
            this.state.isUnsavedMap.set(
              updateStringLookupStatus.params.id,
              false
            )
          ),
        });
      }
      clearStatus(getType(updateStringLookupAsync.success));
    }
    if (
      !prevProps.updateStringLookupStatus.isError &&
      updateStringLookupStatus.isError
    ) {
      message.error('Error updating string lookup');
      if (updateStringLookupStatus.params.id) {
        this.setState({
          isLoadingMap: new Map(
            this.state.isLoadingMap.set(
              updateStringLookupStatus.params.id,
              false
            )
          ),
          isUnsavedMap: new Map(
            this.state.isUnsavedMap.set(
              updateStringLookupStatus.params.id,
              true
            )
          ),
        });
      }
      clearStatus(getType(updateStringLookupAsync.failure));
    }
  }

  onStringLookupChange = (id: number) => (model: string) => {
    const existing = this.state.stringLookups.get(id);
    this.setState({
      stringLookups: new Map(
        this.state.stringLookups.set(id, {
          ...existing!,
          stringLookup: model,
        })
      ),
      isUnsavedMap: new Map(this.state.isUnsavedMap.set(id, true)),
    });
  };

  onStringLookupSave = (id: number) => {
    const item = this.state.stringLookups.get(id);
    if (item) {
      this.setState({
        isLoadingMap: new Map(this.state.isLoadingMap.set(id, true)),
      });
      this.props.updateStringLookup({ ...item });
    }
  };

  onSaveClick = (id: number) => (e) => {
    e.stopPropagation();
    this.onStringLookupSave(id);
  };

  render() {
    const { stringLookups, isUnsavedMap, isLoadingMap } = this.state;
    if (stringLookups.size === 0) {
      return (
        <Card title="String Lookup Editor">
          <Empty />
        </Card>
      );
    }
    return (
      <Card title="String Lookup Editor">
        <Collapse bordered={false}>
          {Array.from(stringLookups.values()).map((s) => {
            const isLoading = isLoadingMap.get(s.id);
            const isUnsaved = isUnsavedMap.get(s.id);
            return (
              <Panel
                header={
                  <div
                    style={{
                      display: 'inline-flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ textTransform: 'capitalize' }}>
                      {s.type.split('_').join(' ')}
                    </span>
                    <Button
                      type={isUnsaved ? 'primary' : 'text'}
                      title="Save"
                      className={`string-lookup-action ${
                        isUnsaved ? 'unsaved' : 'saved'
                      }`}
                      onClick={this.onSaveClick(s.id)}
                      loading={isLoading}
                      icon={
                        isUnsaved ? (
                          <i className="far fa-save" />
                        ) : (
                          <i className="far fa-check-circle" />
                        )
                      }
                    >
                      {isUnsaved ? 'Save' : 'Saved'}
                    </Button>
                  </div>
                }
                key={s.id}
                className="string-lookup-collapse-panel"
              >
                <FroalaCustomUpload
                  model={s.stringLookup}
                  onChange={this.onStringLookupChange(s.id)}
                  config={{
                    heightMax: '50vh',
                  }}
                />
              </Panel>
            );
          })}
        </Collapse>
        <UnsavedLeavingGuard
          when={Array.from(isUnsavedMap.values()).includes(true)}
          navigate={(path) => this.props.history.push(path)}
          shouldBlockNavigation={() =>
            Array.from(isUnsavedMap.values()).includes(true)
          }
        />
      </Card>
    );
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    stringLookups: selectors.getStringLookups(state),
    getStringLookupStatus: selectors.getStringLookupStatus(state),
    getStringLookupsStatus: selectors.getStringLookupsStatus(state),
    updateStringLookupStatus: selectors.updateStringLookupStatus(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    loadStringLookups: (args: GetStringLookupArguments) =>
      dispatch(getStringLookupsAsync.request(args)),
    updateStringLookup: (args: UpdateStringLookupArguments) =>
      dispatch(updateStringLookupAsync.request(args)),
    clearStatus: (type: string) => dispatch(clearStatus(type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StringLookupLandingPage));
