import React, { useState } from 'react';
import { Steps, Card, Empty } from 'antd';
import './forumAddBulk.scss';
import ForumAddBulkUpload from '../../../components/forum/ForumAddBulkUpload';
import ForumAddBulkDetail from '../../../components/forum/ForumAddBulkDetail';
import { ForumUploadPostType } from '../../../../../server/src/types/forumTypes';

const { Step } = Steps;

const ForumAddBulk = () => {
  const [forumPosts, setForumPosts] = useState<ForumUploadPostType[]>();
  const [studyArmId, setStudyArmId] = useState<number>();
  const [current, setCurrent] = React.useState(0);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onExcelUploaded = (forumPostsData) => {
    setForumPosts(forumPostsData);
  };

  const steps = [
    {
      title: 'Upload',
      content: (
        <ForumAddBulkUpload
          onUploaded={onExcelUploaded}
          onStudyArmChange={setStudyArmId}
          onPrev={prev}
          onNext={next}
          current={current}
          stepsLength={2}
          studyArmId={studyArmId}
        />
      ),
    },
    {
      title: 'Detail',
      content:
        forumPosts && studyArmId ? (
          <ForumAddBulkDetail
            forumUploadPosts={forumPosts}
            onPrev={prev}
            onNext={next}
            current={current}
            stepsLength={2}
            studyArmId={studyArmId}
          />
        ) : (
          <Empty />
        ),
    },
  ];

  return (
    <Card
      title={
        <div className="goal-title">
          <h1>Add Forum Posts</h1>
        </div>
      }
    >
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </Card>
  );
};

export default ForumAddBulk;
