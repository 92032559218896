export enum NotificationTypes {
  APPOINTMENT = 'appointment',
  AVAILABILITY = 'availability',
  ACTIVITY = 'activity',
  LOGIN = 'login',
  RESOURCE = 'resource',
  FORUM = 'forum',
  QNA = 'qna',
  MESSAGE = 'message',
  BADGE = 'badge',
  GOAL = 'goal',
  INCENTIVE = 'incentive',
  PLAN = 'plan',

  // not implemented
  INCENTIVE_PAYMENT = 'incentive_payment',
  SURVEY = 'survey',
  TESTKIT = 'testkit',
  REFERRAL = 'referral',
}
export enum NotificationSubTypes {
  // new sub types
  APPOINTMENT_DUE = 'appointment_due',
  APPOINTMENT_DAYS_PRIOR = 'appointment_days_prior',
  APPOINTMENT_CREATED = 'appointment_created',
  APPOINTMENT_RESCHEDULED = 'appointment_rescheduled',
  APPOINTMENT_ASSIGNED = 'appointment_assigned',
  APPOINTMENT_DELETED = 'appointment_deleted',
  APPOINTMENT_CONFIRMED = 'appointment_confirmed',
  ANY_APPOINTMENT_CREATED = 'any_appointment_created',
  ANY_APPOINTMENT_ASSIGNED = 'any_appointment_assigned',
  ANY_APPOINTMENT_DELETED = 'any_appointment_deleted',
  ANY_APPOINTMENT_RESCHEDULED = 'any_appointment_rescheduled',
  ANY_AVAILABILITY_ADD = 'any_availability_added',
  ANY_AVAILABILITY_DELETED = 'any_availability_deleted',
  ANY_APPOINTMENT_CHANGED = 'any_appointment_changed',

  MY_APPOINTMENT_RESCHEDULED = 'my_appointment_rescheduled',
  MY_AVAILABILITY_ADD = 'my_availability_added',
  MY_AVAILABILITY_DELETED = 'my_availability_deleted',
  MY_APPOINTMENT_ASSIGNED = 'my_appointment_assigned',
  MY_APPOINTMENT_CREATED = 'my_appointment_created',
  MY_APPOINTMENT_DELETED = 'my_appointment_deleted',
  MY_APPOINTMENT_CHANGED = 'my_appointment_changed',

  ANY_AVAILABILITY_ADDED = 'any_availability_added',
  MY_AVAILABILITY_ADDED = 'my_availability_added',
  LOGIN = 'login',
  NEW_ACTIVITY = 'new_activity',
  NEW_RESOURCE = 'new_resource',

  FORUM_NEW_POST = 'forum_new_post',
  FORUM_POST_COMMENT = 'forum_post_comment',
  FORUM_POST_LIKE = 'forum_post_like',
  QNA_POST_COMMENT = 'qna_post_comment',
  QNA_POST_LIKE = 'qna_post_like',
  QNA_ASKED = 'qna_asked',
  // TODO: make sure there are no collisions for notification types/subtypes
  NEW_ASK_THE_EXPERT = 'new_ask_the_expert',
  ASK_THE_EXPERT_AD_HOC = 'ask_the_expert_ad_hoc',

  NEW_MESSAGE = 'new_message',
  ANY_NEW_MESSAGE = 'any_new_message',
  NEW_MESSAGE_IN_MY_THREAD = 'new_message_in_my_thread',
  THREAD_ASSIGNED_TO_ME = 'thread_assigned_to_me',

  NEW_GOAL = 'new_goal',
  GOAL_REMINDER = 'goal_reminder',
  GOAL_INACTIVE_REMINDER = 'goal_inactive_reminder',

  BADGE_EARNED = 'badge_earned',

  PLAN_NOT_ACCESSED = 'plan_not_accessed',

  // not implemented
  INCENTIVE_PAYMENT = 'incentive_payment',
  SURVEY = 'survey',
  TESTKIT = 'testkit',
  REFERRAL = 'referral',

  SURVEY_OPEN = 'survey_open',
  REFERRAL_peer_ENROLLED = 'referral_peer_enrolled',
  REFERRAL_UNUSED_REMINDER = 'referral_unused_reminder',
}

export enum MessageDeliveryMethods {
  EMAIL = 'email',
  SMS = 'sms',
  IN_APP = 'in_app',
  PUSH = 'push',
}

export enum MessageDeliveryMethodFlags {
  EMAIL = 1 << 0,
  SMS = 1 << 1,
  IN_APP = 1 << 2,
  PUSH = 1 << 3,
}

export enum NotificationMessageStatus {
  SUCCESS = 1,
  ERROR = 2,
}
