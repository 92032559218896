import { ForumUploadPostType } from '../types/serverTypes/forumTypes';
import axios from '../redux/api';

export const createForumPostsInBulk = async (
  forumPostData: ForumUploadPostType[],
  studyArmId: number
) => {
  const response = await axios.post(`/a/forum/bulkAdd`, {
    forumUploadData: forumPostData,
    studyArmId,
    imageBaseUrl: `${window.location.protocol}//${window.location.host}`,
  });
  return response.data;
};
