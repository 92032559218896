import React, { useState } from 'react';
import { Steps } from 'antd';
import ParticipantPlanDetailEdit from './ParticipantPlanDetailEdit';
import { PlanType } from '../../../types/serverTypes/planTypes';
import SelectPlan from './SelectPlan';
import '../Plan.scss';

const { Step } = Steps;

interface AssociatePlanProp {
  participantId: number;
  onDone: () => void;
  onCancel: () => void;
}
const AssociatePlan = (props: AssociatePlanProp) => {
  const [current, setCurrent] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState<PlanType>();

  const onNext = (plan: PlanType) => {
    setSelectedPlan(plan);
    setCurrent(current + 1);
  };
  const steps = [
    {
      title: 'Select Plan',
      content: (
        <SelectPlan
          onCancel={props.onCancel}
          onNext={onNext}
          participantId={props.participantId}
        />
      ),
    },
    {
      title: 'Edit Detail',
      content: selectedPlan ? (
        <ParticipantPlanDetailEdit
          participantId={props.participantId}
          onDone={props.onDone}
          plan={selectedPlan}
        />
      ) : null,
    },
  ];

  return (
    <>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div>{steps[current].content}</div>
    </>
  );
};

export default AssociatePlan;
